import { Clipboard } from "@capacitor/clipboard";
import { Button as RawButton } from "@kobalte/core/button";
import { Dialog } from "@kobalte/core/dialog";
import { Popover } from "@kobalte/core/popover";
import { Show, createMemo, createSignal } from "solid-js";
import { renderSVG as renderQRAsSVG } from "uqr";
import qrBackground from "~/assets/qr-background.svg";
import { type FragmentOf, graphql, readFragment } from "~/lib/gql/tada";
import { cn } from "~/lib/utils";
import { Button } from "./ui/button";
import { Image } from "./ui/image";
import { showToast } from "./ui/toast";

type YoutubeActionButtonProps = {
	$music?: FragmentOf<typeof YoutubeActionButtonFragment>;
	environment: "desktop" | "mobile";
	desktopButtonClass?: string;
	onActionsClick?: () => void;
};

export const YoutubeActionButtonFragment = graphql(`
	fragment YoutubeActionButton on Music {
		title {
			en
		}
		coverImage
		isrc
		youtubeVideoId
	}
`);

export const YoutubeActionButton = (props: YoutubeActionButtonProps) => {
	const [showPopover, setShowPopover] = createSignal(false);
	const [showQRDialog, setShowQRDialog] = createSignal(false);

	const music = createMemo(() =>
		readFragment(YoutubeActionButtonFragment, props.$music),
	);

	return (
		<>
			<Show when={props.environment === "desktop" && music()}>
				{(music) => (
					<>
						<Popover
							placement="bottom-end"
							gutter={6}
							open={showPopover()}
							onOpenChange={setShowPopover}
						>
							<Popover.Trigger
								class={cn(
									"mx-auto size-40px flex justify-center items-center rounded-6px hover:bg-background-dark",
									props.desktopButtonClass,
								)}
							>
								<i class="inline-block size-24px i-bp-channel" />
							</Popover.Trigger>
							<Popover.Portal>
								<Popover.Content class="z-999 bg-white rounded-10px p-20px w-380px flex flex-col gap-10px shadow-popover">
									<Popover.Title class="prose-md-b text-primary">
										{music().isrc}
									</Popover.Title>
									<Popover.Description class="flex flex-col gap-20px">
										<ul class="list-disc pl-20px text-secondary font-15px leading-[1.4]">
											<li>
												Uploading music from desktop may cause inaccurate view
												counts. We recommend using mobile for uploads.
											</li>
											<li>
												This track is available for use in all videos, including
												monetized ones.
											</li>
										</ul>
										<div class="flex gap-10px">
											<Button
												class="flex-1"
												onClick={async () => {
													setShowPopover(false);
													await Clipboard.write({
														string: music().isrc,
													});
													showToast({
														class: "px-16px! pr-20px rounded-10px! w-fit",
														message: (
															<div class="flex items-center gap-10px">
																<i class="i-bp-info size-20px text-white" />
																<div>
																	<p class="text-white text-sm-b leading-[1.2]">
																		Code copied
																	</p>
																</div>
															</div>
														),
													});
												}}
											>
												<i class="inline-block size-16px i-bp-copy" />
												Copy Code
											</Button>
											<Button
												class="flex-1"
												onClick={() => {
													setShowPopover(false);
													setShowQRDialog(true);
												}}
											>
												<i class="inline-block size-16px i-bp-qr" />
												QR Code
											</Button>
										</div>
									</Popover.Description>
								</Popover.Content>
							</Popover.Portal>
						</Popover>
						<Dialog open={showQRDialog()} onOpenChange={setShowQRDialog}>
							<Dialog.Portal>
								<Dialog.Overlay class="fixed inset-0 z-10 bg-black/30" />
								<Dialog.Content class="fixed inset-0 m-auto size-fit p-16px flex flex-col items-center gap-16px bg-white rounded-10px z-15">
									<div class="flex justify-end self-stretch">
										<Dialog.CloseButton class="size-32px flex justify-center items-center">
											<i class="size-16px inline-block i-bp-close text-primary" />
										</Dialog.CloseButton>
									</div>
									<Dialog.Title class="prose-unbounded-lg">
										Scan QR code
									</Dialog.Title>
									<div
										class="size-200px p-40px"
										style={{
											"background-image": `url(${qrBackground})`,
										}}
										innerHTML={renderQRAsSVG(
											`vnd.youtube://source/${music().youtubeVideoId}/shorts`,
										)}
									/>
									<div class="w-336px rounded-6px bg-background-dark px-12px py-8px flex gap-12px">
										<Image
											suspend={false}
											src={new URL(
												music().coverImage,
												import.meta.env.VITE_API_BASE,
											).toString()}
											alt={`Album art of ${music().title.en}`}
											class="size-40px object-cover rounded-4px"
										/>
										<div class="flex flex-col justify-center gap-2px">
											<span class="prose-sm text-primary">
												{music().title.en}
											</span>
											<span class="prose-xs text-secondary">
												{music().isrc}
											</span>
										</div>
									</div>
								</Dialog.Content>
							</Dialog.Portal>
						</Dialog>
					</>
				)}
			</Show>
			<Show when={props.environment === "mobile" && music()}>
				<a
					href={`vnd.youtube://source/${music()?.youtubeVideoId}/shorts`}
					class="flex items-center w-fit mx-auto"
				>
					<i class="inline-block size-24px i-bp-channel" />
				</a>
				<RawButton
					class="i-bp-ellipsis-vertical text-tertiary size-16px"
					onClick={() => props.onActionsClick?.()}
				/>
			</Show>
		</>
	);
};
