import { Capacitor } from "@capacitor/core";
import { basicUIPlugin } from "@contentstech/stackflow-plugin-basic-ui/solid";
import { historySyncPlugin } from "@contentstech/stackflow-plugin-history-sync/solid";
import { omniflow } from "@contentstech/stackflow-plugin-omniflow";
import { basicRendererPlugin } from "@contentstech/stackflow-plugin-renderer-basic/solid";
import {
	type ActivityComponentType,
	stackflow,
} from "@contentstech/stackflow-solid/future";
import type { RegisteredActivityName } from "@stackflow/config";
import { EmailSignUpActivity } from "~/activities/auth/emailSignUp";
import { EmailSignUpVerifyActivity } from "~/activities/auth/emailSignUpVerify";
import { RequestPasswordResetActivity } from "~/activities/auth/requestPasswordReset";
import { ResetPasswordActivity } from "~/activities/auth/resetPassword";
import { SignInActivity } from "~/activities/auth/signIn";
import { SignUpActivity } from "~/activities/auth/signUp";
import RootActivity from "~/activities/root";
import DashboardActivity from "~/activities/root/dashboard";
import MusicActivity from "~/activities/root/music";
import FavoritesActivity from "~/activities/root/music/favorites";
import PayoutActivity from "~/activities/root/payout";
import ProfileActivity from "~/activities/root/profile";
import SettingsActivity from "~/activities/settings";
import AccountSettingsActivity from "~/activities/settings/account";
import ChannelsSettingsActivity from "~/activities/settings/channels";
import PayoutSettingsActivity from "~/activities/settings/payout";
import PayoutDetailsActivity from "~/activities/settings/payoutDetails";
import { AuthGuard } from "~/components/authGuard";
import { environment } from "~/lib/environment";
import { deepLinkPlugin } from "./deepLinkPlugin";
import { config } from "./stackflow.config";

// biome-ignore lint/suspicious/noExplicitAny: intended
const components: Record<RegisteredActivityName, ActivityComponentType<any>> = {
	RootActivity: (props) => (
		<AuthGuard type="require-auth">
			<RootActivity {...props} />
		</AuthGuard>
	),
	DashboardActivity: (props) => (
		<AuthGuard type="require-auth">
			<DashboardActivity {...props} />
		</AuthGuard>
	),
	MusicActivity: (props) => (
		<AuthGuard type="require-auth">
			<MusicActivity {...props} />
		</AuthGuard>
	),
	PayoutActivity: (props) => (
		<AuthGuard type="require-auth">
			<PayoutActivity {...props} />
		</AuthGuard>
	),
	PayoutDetailsActivity: (props) => (
		<AuthGuard type="require-auth">
			<PayoutDetailsActivity {...props} />
		</AuthGuard>
	),
	ProfileActivity: (props) => (
		<AuthGuard type="require-auth">
			<ProfileActivity {...props} />
		</AuthGuard>
	),
	FavoritesActivity: (props) => (
		<AuthGuard type="require-auth">
			<FavoritesActivity {...props} />
		</AuthGuard>
	),
	SettingsActivity: (props) => (
		<AuthGuard type="require-auth">
			<SettingsActivity {...props} />
		</AuthGuard>
	),
	AccountSettingsActivity: (props) => (
		<AuthGuard type="require-auth">
			<AccountSettingsActivity {...props} />
		</AuthGuard>
	),
	ChannelsSettingsActivity: (props) => (
		<AuthGuard type="require-auth">
			<ChannelsSettingsActivity {...props} />
		</AuthGuard>
	),
	PayoutSettingsActivity: (props) => (
		<AuthGuard type="require-auth">
			<PayoutSettingsActivity {...props} />
		</AuthGuard>
	),
	SignUpActivity: (props) => (
		<AuthGuard type="require-no-auth">
			<SignUpActivity {...props} />
		</AuthGuard>
	),
	EmailSignUpActivity: (props) => (
		<AuthGuard type="require-no-auth">
			<EmailSignUpActivity {...props} />
		</AuthGuard>
	),
	EmailSignUpVerifyActivity: (props) => (
		<AuthGuard type="require-no-auth">
			<EmailSignUpVerifyActivity {...props} />
		</AuthGuard>
	),
	SignInActivity: (props) => (
		<AuthGuard type="require-no-auth">
			<SignInActivity {...props} />
		</AuthGuard>
	),
	RequestPasswordResetActivity: (props) => (
		<RequestPasswordResetActivity {...props} />
	),
	ResetPasswordActivity: (props) => (
		<AuthGuard type="require-auth">
			<ResetPasswordActivity {...props} />
		</AuthGuard>
	),
};

export const { Stack } = stackflow({
	config,
	components,
	plugins: [
		basicRendererPlugin(),
		basicUIPlugin({
			theme: "cupertino",
			backgroundColor: "rgb(var(--background))",
		}),
		Capacitor.isNativePlatform() ? deepLinkPlugin() : [],
		() => {
			const o = omniflow({ config, components, environment })();
			const h = historySyncPlugin({
				config,
				fallbackActivity: () => "RootActivity" as const,
			})();

			return {
				...o,
				...h,
				key: "omniflow-history-sync",
				overrideInitialEvents(args) {
					const newInitialEvents = h.overrideInitialEvents?.(args);
					return (
						o.overrideInitialEvents?.({
							...args,
							initialEvents: newInitialEvents ?? args.initialEvents,
						}) ?? args.initialEvents
					);
				},
				onBeforePush(args) {
					let defaultPrevented = false;
					let actionParams = args.actionParams;
					o.onBeforePush?.({
						actionParams,
						actions: {
							...args.actions,
							preventDefault: () => {
								defaultPrevented = true;
								args.actions.preventDefault();
							},
							overrideActionParams: (params) => {
								args.actions.overrideActionParams(params);
								actionParams = {
									...actionParams,
									...params,
								};
							},
						},
					});
					if (!defaultPrevented) h.onBeforePush?.({ ...args, actionParams });
				},
				onBeforeReplace(args) {
					let defaultPrevented = false;
					let actionParams = args.actionParams;
					o.onBeforeReplace?.({
						actionParams,
						actions: {
							...args.actions,
							preventDefault: () => {
								defaultPrevented = true;
								args.actions.preventDefault();
							},
							overrideActionParams: (params) => {
								args.actions.overrideActionParams(params);
								actionParams = {
									...actionParams,
									...params,
								};
							},
						},
					});
					if (!defaultPrevented) h.onBeforeReplace?.({ ...args, actionParams });
				},
				onBeforePop(args) {
					let defaultPrevented = false;
					let actionParams = args.actionParams;
					o.onBeforePop?.({
						actionParams,
						actions: {
							...args.actions,
							preventDefault: () => {
								defaultPrevented = true;
								args.actions.preventDefault();
							},
							overrideActionParams: (params) => {
								args.actions.overrideActionParams(params);
								actionParams = {
									...actionParams,
									...params,
								};
							},
						},
					});
					if (!defaultPrevented) h.onBeforePop?.({ ...args, actionParams });
				},
			};
		},
	],
});
