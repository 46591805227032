import { ActionSheet } from "@capacitor/action-sheet";
import {
	type ActivityComponentType,
	useFlow,
	useStack,
} from "@contentstech/stackflow-solid/future";
import { Show, createSignal } from "solid-js";
import logoDesktop from "~/assets/logo-bg.png";
import { AuthAppScreen } from "~/components/authAppScreen";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import { Dialog, DialogContent, DialogHeader } from "~/components/ui/dialog";
import { Image } from "~/components/ui/image";
import {
	OTPField,
	OTPFieldGroup,
	OTPFieldInput,
	OTPFieldSlot,
} from "~/components/ui/otp";
import { Toaster, showToast } from "~/components/ui/toast";
import { useTopAlert } from "~/components/ui/topAlert";
import { environment } from "~/lib/environment";
import { useKeyboard } from "~/lib/keyboard";
import { supabase } from "~/lib/supabase";

declare module "@stackflow/config" {
	interface Register {
		EmailSignUpVerifyActivity: {
			email: string;
		};
	}
}

export const EmailSignUpVerifyActivity: ActivityComponentType<
	"EmailSignUpVerifyActivity"
> = (props) => {
	const [otp, setOTP] = createSignal("");
	const stack = useStack();
	const topAlert = useTopAlert();
	const keyboard = useKeyboard();
	const [showTroubleDialog, setShowTroubleDialog] = createSignal(false);
	const actions = useFlow();

	const handleVerifyTrouble = async (target: "resend" | "different-email") => {
		switch (target) {
			case "resend":
				await resend();
				break;
			case "different-email":
				actions.pop({ animate: false });
				actions.replace("EmailSignUpActivity", {});
				break;
			default:
				throw new Error(`Unknown target: ${target}`);
		}
	};
	const onTroubleVerifyingClicked = async () => {
		if (environment === "desktop") {
			setShowTroubleDialog(true);
			return;
		}

		const response = await ActionSheet.showActions({
			options: [
				{ title: "Resend" },
				{ title: "Sign up with a different email" },
				{ title: "Cancel" },
			],
		});
		// Cancel is index 2
		if (response.index === 2) return;
		handleVerifyTrouble(response.index === 0 ? "resend" : "different-email");
	};

	const resend = async () => {
		const { error } = await supabase.auth.resend({
			type: "signup",
			email: props.params.email,
		});
		if (error) {
			topAlert.open({
				variant: "error",
				children: (
					<div>
						<p>Failed to resend verification code</p>
						<p>Please try again</p>
					</div>
				),
			});
		} else {
			if (environment === "desktop") {
				showToast({
					class: "px-16px! pr-20px rounded-10px!",
					message: (
						<div class="flex items-center gap-10px">
							<i class="i-bp-info size-20px text-white" />
							<div>
								<p class="text-white text-sm-b leading-[1.2]">
									A verification email has been resent 💌
								</p>
								<p class="text-white text-sm-b leading-[1.2]">
									Please check your inbox!
								</p>
							</div>
						</div>
					),
					duration: 5000,
				});
			} else {
				topAlert.open({
					variant: "success",
					children: (
						<div>
							<p>A verification email has been resent 💌</p>
							<p>Please check your inbox!</p>
						</div>
					),
				});
			}
		}
	};

	const [verifyInFlight, setVerifyInFlight] = createSignal(false);
	const verify = async () => {
		setVerifyInFlight(true);
		try {
			const response = await supabase.auth.verifyOtp({
				type: "signup",
				email: props.params.email,
				token: otp(),
			});
			if (response.error) {
				topAlert.open({
					variant: "error",
					children: (
						<div>
							<p>Failed to verify email</p>
							<p>Please try again</p>
						</div>
					),
				});
			} else {
				actions.pop(stack().activities.length, { animate: false });
				actions.replace("RootActivity", {}, { animate: false });
			}
		} finally {
			setVerifyInFlight(false);
		}
	};

	return (
		<AuthAppScreen class="env-desktop:(justify-center items-center)">
			<Toaster
				class="transition-all bottom-auto top-24px w-364px left-1/2 -translate-x-1/2 z-20"
				regionProps={{
					swipeDirection: "up",
					pauseOnInteraction: false,
					limit: 1,
				}}
			/>
			{/* TODO: Update toast to slide down from top */}
			<Show when={environment === "mobile"}>
				<AppBar />
			</Show>
			<div class="flex flex-col text-left max-w-500px px-16px env-mobile:(flex-grow-1)">
				<Show when={environment === "desktop"}>
					<Image
						src={logoDesktop}
						alt="BackPac Logo"
						class="size-56px mb-20px"
					/>
				</Show>
				<h1 class="prose-unbounded-xl leading-[1.4] text-primary env-desktop:(prose-unbounded-2xl leading-[1.3])">
					We emailed you a verification code
				</h1>
				<div class="flex flex-col gap-12px mt-12px env-desktop:(mt-16px)">
					<p class="text-secondary prose-sm leading-[1.3] env-desktop:(leading-[1.4])">
						Code sent to: <b>{props.params.email}</b>
						<br />
						Verify your email address to activate your account and start using
						BackPac.
					</p>
				</div>
				<OTPField
					maxLength={6}
					class="mt-24px env-desktop:(self-center w-342px)"
					onValueChange={(v) => setOTP(v)}
				>
					<OTPFieldInput autocomplete="off" class="w-100%!" />
					<OTPFieldGroup class="flex-1 justify-evenly">
						<OTPFieldSlot index={0} />
						<OTPFieldSlot index={1} />
						<OTPFieldSlot index={2} />
						<OTPFieldSlot index={3} />
						<OTPFieldSlot index={4} />
						<OTPFieldSlot index={5} />
					</OTPFieldGroup>
				</OTPField>
				<div class="flex flex-col items-center env-mobile:(items-start)">
					<Show when={environment === "desktop"}>
						<Button
							variant="highlighted"
							class="self-stretch mt-40px"
							size="default"
							disabled={otp().length < 6 || verifyInFlight()}
							onClick={verify}
						>
							Continue
						</Button>
					</Show>
					<Button
						variant="offWhite"
						size="xs"
						class="mt-40px"
						onClick={onTroubleVerifyingClicked}
					>
						Have trouble verifying?
					</Button>
				</div>
				<Show when={environment === "mobile"}>
					<div
						class="absolute top-0 inset-x-0 px-4 flex flex-col stretch items-stretch transition-transform duration-300"
						style={{
							transform: `translateY(calc(100vh - 100% - max(${keyboard.height()}px, var(--safe-area-inset-bottom)) - 16px))`,
						}}
					>
						<Button
							variant="highlighted"
							disabled={otp().length < 6 || verifyInFlight()}
							onClick={verify}
						>
							Continue
						</Button>
					</div>
				</Show>
				<Show when={environment === "desktop"}>
					<Dialog
						open={showTroubleDialog()}
						onOpenChange={setShowTroubleDialog}
					>
						<DialogContent class="items-center w-100% max-w-480px p-24px! pt-16px! gap-0!">
							<DialogHeader
								class="flex justify-end!"
								onClose={() => {
									setShowTroubleDialog(false);
								}}
							/>
							<div class="flex flex-col gap-10px mt-20px">
								<Button
									variant="outline"
									class="text-primary border-2 hover:bg-border-light"
									onClick={async () => {
										await handleVerifyTrouble("resend");
										setShowTroubleDialog(false);
									}}
								>
									Resend
								</Button>
								<Button
									variant="outline"
									class="text-primary border-2 hover:bg-border-light"
									onClick={() => handleVerifyTrouble("different-email")}
								>
									Sign up with a different email
								</Button>
							</div>
						</DialogContent>
					</Dialog>
				</Show>
			</div>
		</AuthAppScreen>
	);
};
