import { App } from "@capacitor/app";
import { Preferences } from "@capacitor/preferences";
import { type Session, createClient } from "@supabase/supabase-js";
import { createSignal } from "solid-js";

export const supabase = createClient(
	import.meta.env.VITE_SUPABASE_URL ?? "",
	import.meta.env.VITE_SUPABASE_KEY ?? "",
	{
		auth: {
			storage: {
				getItem: async (key) => Preferences.get({ key }).then((v) => v.value),
				setItem: async (key, value) => Preferences.set({ key, value }),
				removeItem: async (key) => Preferences.remove({ key }),
			},
			autoRefreshToken: true,
			persistSession: true,
		},
	},
);

const [session, setSession] = createSignal<Session | null>();

export { session };

export const initSession = () =>
	supabase.auth.getSession().then((res) => setSession(res.data.session));

supabase.auth.onAuthStateChange((_, session) => {
	setSession(session);
});

App.addListener("appStateChange", (state) => {
	if (state.isActive) {
		supabase.auth.startAutoRefresh();
	} else {
		supabase.auth.stopAutoRefresh();
	}
});
