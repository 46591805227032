import { AppScreen } from "@contentstech/stackflow-plugin-basic-ui/solid";
import { useParent } from "@contentstech/stackflow-plugin-omniflow";
import type { ActivityComponentType } from "@contentstech/stackflow-solid/future";
import { For, Show, Suspense, createMemo, createSignal } from "solid-js";
import { Dynamic } from "solid-js/web";
import { Passthrough } from "~/components/passthrough";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "~/components/ui/dialog";
import { Image } from "~/components/ui/image";
import { useAddChannel } from "~/lib/actions/addChannel";
import { useDeleteChannel } from "~/lib/actions/removeChannel";
import { environment } from "~/lib/environment";
import { createToeQuery } from "~/lib/gql/createToeQuery";
import { graphql, n } from "~/lib/gql/tada";
import { latest } from "~/lib/latest";

declare module "@stackflow/config" {
	interface Register {
		ChannelsSettingsActivity: Record<string, never>;
	}
}

const ChannelsSettingsQuery = graphql.persisted(
	"sha256:2081b0a1cd4878ad51ee8faaeb8488896704f1f67f3a336d2061c401d4b8457f",
	graphql(`
		query ChannelsSettings {
			ownedChannels {
				id
				name
				profileImage
				customUrl
				subscriberCount
			}
		}
	`),
);

type Channel = {
	id: string;
	name: string;
	profileImage: string;
};

const ChannelsSettingsActivity: ActivityComponentType<
	"ChannelsSettingsActivity"
> = () => {
	const addChannel = useAddChannel();
	const removeChannel = useDeleteChannel();
	const [channelToDelete, setChannelToDelete] = createSignal<
		Channel | undefined
	>(undefined);
	const [query, refetchQuery] = createToeQuery({
		query: ChannelsSettingsQuery,
	});
	const channelToDeleteLatest = createMemo<Channel | undefined>((prev) => {
		const curr = channelToDelete();
		if (curr == null) return prev;
		return curr;
	});
	const parent = useParent();

	return (
		<Dynamic component={parent ? Passthrough : AppScreen}>
			<div class="flex flex-col min-h-full pb-[var(--safe-area-inset-bottom)]">
				<AppBar
					title="Manage channels"
					actions={[
						<Show when={environment === "desktop"}>
							<Button variant="highlighted" size="sm" onClick={addChannel}>
								Add channel
							</Button>
						</Show>,
					]}
					topActions={[
						<Show
							when={
								environment === "mobile" &&
								latest(() => query.data?.ownedChannels.length) !== 0
							}
						>
							<Button variant="outline" size="sm" onClick={addChannel}>
								Add channel
								<i class="inline-block i-bp-chevron-right" />
							</Button>
						</Show>,
					]}
				/>
				<Suspense>
					<Show
						when={query.data?.ownedChannels.length !== 0}
						fallback={
							<div class="flex-1 flex flex-col gap-30px env-mobile:(items-center justify-center) env-desktop:(items-center mt-120px flex-col-reverse justify-end gap-16px)">
								<div class="flex flex-col gap-8px text-center">
									<h2 class="prose-md-b text-primary">
										No channels connected yet
									</h2>
									<p class="prose-sm text-secondary">
										Add your first channel to start managing
										<br />
										and tracking your YouTube Shorts
									</p>
								</div>
								{environment === "desktop" ? (
									<div class="i-bp-channel-bold size-24px" />
								) : (
									<Button variant="highlighted" size="sm" onClick={addChannel}>
										Add channel
									</Button>
								)}
							</div>
						}
					>
						<ul class="flex flex-col px-16px my-16px">
							<For each={query.data?.ownedChannels}>
								{(channel) => (
									<li class="group flex flex-col">
										<div class="w-full h-0.75px bg-border-light" />
										<div class="flex items-center gap-12px py-16px">
											<Image
												src={channel.profileImage}
												alt={`Channel profile image of ${channel.name}`}
												class="size-48px rounded-full overflow-hidden"
											/>
											<div class="flex flex-col gap-2px flex-1">
												<h2 class="prose-md text-black">{channel.name}</h2>
												<p class="prose-xs text-tertiary">
													{channel.customUrl}
												</p>
												<p class="prose-xs text-tertiary">
													{n(channel.subscriberCount).toLocaleString()}{" "}
													subscribers
												</p>
											</div>

											<div class="flex-1" />
											<Button
												variant="outline"
												size="sm"
												class="env-mobile:border-none"
												onClick={() => setChannelToDelete(channel)}
											>
												{environment === "desktop" ? (
													"Delete"
												) : (
													<i class="i-bp-ellipsis text-tertiary" />
												)}
											</Button>
										</div>
										<div class="w-full h-0.75px bg-border-light hidden group-last:block" />
									</li>
								)}
							</For>
						</ul>
					</Show>
				</Suspense>
			</div>
			<Dialog
				open={channelToDelete() !== undefined}
				onOpenChange={() => setChannelToDelete(undefined)}
			>
				<DialogContent class="env-mobile:m-56px">
					<DialogHeader
						onClose={
							environment === "desktop"
								? () => setChannelToDelete(undefined)
								: undefined
						}
					>
						<DialogTitle>
							{environment === "desktop"
								? `Delete ‘${channelToDeleteLatest()?.name}’?`
								: `Are you sure you want to delete ‘${channelToDeleteLatest()?.name}’?`}
						</DialogTitle>
					</DialogHeader>
					<p class="text-center env-desktop:text-left prose-sm">
						Deleting your channel will stop earnings from being counted and
						negatively impact your monetization.
					</p>
					<DialogFooter>
						<Button
							class="h-48px text-white env-desktop:(w-120px h-42px)"
							size="sm"
							onClick={async () => {
								await removeChannel(channelToDelete()!.id);
								refetchQuery({ requestPolicy: "network-only" });
								setChannelToDelete(undefined);
							}}
						>
							Delete
						</Button>
						<Button
							variant={environment === "mobile" ? "text" : "text-only"}
							size="sm"
							class="text-tertiary env-desktop:h-42px"
							onClick={() => setChannelToDelete(undefined)}
						>
							Cancel
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</Dynamic>
	);
};

export default ChannelsSettingsActivity;
