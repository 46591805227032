import { AppScreen } from "@contentstech/stackflow-plugin-basic-ui/solid";
import type { ActivityComponentType } from "@contentstech/stackflow-solid/future";
import type { FragmentOf } from "gql.tada";
import { For, Show, createSignal } from "solid-js";
import { createMemo } from "solid-js";
import {
	MusicActionsDrawer,
	MusicActionsDrawerFragment,
	MusicListItem,
	MusicListItemFragment,
} from "~/components/musicListItem";
import { AppBar } from "~/components/ui/appbar";
import { createVirtualizer } from "~/lib/createVirtualizer";
import { createToeQuery } from "~/lib/gql/createToeQuery";
import { graphql } from "~/lib/gql/tada";
import { latest } from "~/lib/latest";
import { cn } from "~/lib/utils";

const PAGE_SIZE = 10;
const ITEM_HEIGHT = 48; //82;
const GAP = 16;

declare module "@stackflow/config" {
	interface Register {
		FavoritesActivity: Record<string, never>;
	}
}

const FavoritesQuery = graphql.persisted(
	"sha256:a439b6b33376e64450445fb8381577f49463dd5adff31a2aa69e50c7ab297524",
	graphql(
		`
			query Favorites($first: Int!, $after: String) {
				musics(first: $first, after: $after, filter: { favorites: true }) {
					nodes {
						id
						...MusicListItem
						...MusicActionsDrawer
					}
					pageInfo {
						hasNextPage
						endCursor
					}
				}
			}
		`,
		[MusicListItemFragment, MusicActionsDrawerFragment],
	),
);

const FavoritesActivity: ActivityComponentType<"FavoritesActivity"> = () => {
	const [songDrawerOpen, setSongDrawerOpen] = createSignal(false);
	const [drawerMusic, setDrawerMusic] = createSignal<
		FragmentOf<typeof MusicActionsDrawerFragment> & { id: string }
	>();

	const [after, setAfter] = createSignal<string | undefined>(undefined);
	const [query] = createToeQuery({
		query: FavoritesQuery,
		variables: () => ({ first: PAGE_SIZE, after: after() }),
	});
	const itemCount = createMemo(
		() => latest(() => query.data?.musics?.nodes.length) ?? 0,
	);

	const { scrollContainerRef, contentHeight, visibleItems } = createVirtualizer(
		{
			itemCount,
			skeletonCountBase: 10,
			size: ITEM_HEIGHT,
			gap: GAP,
			overscan: 10,
			hasMoreContents: () =>
				!!latest(() => query.data?.musics?.pageInfo?.hasNextPage),
			onSkeletonReached: () => {
				if (!query.fetching && query.data?.musics?.pageInfo?.endCursor) {
					setAfter(query.data?.musics?.pageInfo?.endCursor);
				}
			},
		},
	);

	return (
		<AppScreen>
			<AppBar title="Favorites" subtitle={itemCount().toString()} />
			<Show
				when={itemCount() !== 0}
				fallback={
					<div class="flex flex-col justify-center items-center gap-2 absolute inset-0 pointer-events-none">
						<span class="prose-md-b">No favorites yet</span>
						<span class="prose-sm text-tertiary">
							Songs that you favorite will appear here.
						</span>
					</div>
				}
			>
				<div
					ref={scrollContainerRef}
					class="relative overflow-y-auto no-scrollbar"
				>
					<div
						class={cn("relative my-10px")}
						style={{
							height: `${contentHeight()}px`,
						}}
					>
						<For each={visibleItems()}>
							{(item) => (
								<Show
									when={query.data?.musics?.nodes[item.index]}
									fallback={
										<MusicListItem
											class="absolute top-0 left-0 w-full"
											style={{ transform: `translateY(${item.top}px)` }}
											onPlayingChange={() => {}}
										/>
									}
								>
									{(node) => (
										<MusicListItem
											$music={node()}
											class="absolute top-0 left-0 w-full"
											style={{ transform: `translateY(${item.top}px)` }}
											onPlayingChange={() => {}}
											onActionsClick={() => {
												setDrawerMusic(node());
												setSongDrawerOpen(true);
											}}
										/>
									)}
								</Show>
							)}
						</For>
					</div>
				</div>
			</Show>
			<Show when={drawerMusic()}>
				{(music) => (
					<MusicActionsDrawer
						$music={music()}
						isOpen={songDrawerOpen()}
						onOpenchange={setSongDrawerOpen}
					/>
				)}
			</Show>
		</AppScreen>
	);
};

export default FavoritesActivity;
