import { InAppReview } from "@capacitor-community/in-app-review";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import {
	type ActivityComponentType,
	useFlow,
} from "@contentstech/stackflow-solid/future";
import {
	For,
	type JSX,
	Match,
	Show,
	Suspense,
	Switch,
	createResource,
} from "solid-js";
import { Dynamic } from "solid-js/web";
import { AppBar } from "~/components/ui/appbar";
import { WebLink } from "~/components/webLink";
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "~/lib/constants/urls";
import { cn } from "~/lib/utils";

declare module "@stackflow/config" {
	interface Register {
		ProfileActivity: Record<string, never>;
	}
}

const ProfileActivity: ActivityComponentType<"ProfileActivity"> = () => {
	const [appInfo] = createResource(() => App.getInfo().catch(() => null));
	const actions = useFlow();

	return (
		<div class="flex flex-col">
			<AppBar title="Profile" />
			<div class="flex flex-col pt-20px gap-30px px-16px">
				<MenuSection
					title="Settings"
					items={[
						{
							type: "action",
							icon: <i class="inline-block i-bp-user" />,
							label: "Account",
							onClick: () => {
								actions.push("AccountSettingsActivity", {});
							},
						},
						{
							type: "action",
							icon: <i class="inline-block i-bp-channel-bold" />,
							label: "Manage channels",
							onClick: () => {
								actions.push("ChannelsSettingsActivity", {});
							},
						},
						{
							type: "action",
							icon: <i class="inline-block i-bp-invoice" />,
							label: "Payout settings",
							onClick: () => {
								actions.push("PayoutDetailsActivity", {});
							},
						},
					]}
				/>
				<MenuSection
					title="Resources & Support"
					items={[
						...(Capacitor.isNativePlatform()
							? ([
									{
										type: "action",
										icon: <i class="inline-block i-bp-star" />,
										label: "Rate the app",
										external: true,
										onClick: () => {
											InAppReview.requestReview();
										},
									},
								] as const)
							: []),
						{
							type: "link",
							icon: <i class="inline-block i-bp-email" />,
							label: "Give us your feedback 💌",
							href: "mailto:support@backpac.app",
						},
						{
							type: "link",
							icon: <i class="inline-block i-bp-help" />,
							label: "Help center",
							href: "https://help.backpac.app",
						},
					]}
				/>
				<MenuSection
					title="Other"
					items={[
						{
							type: "link",
							icon: <i class="inline-block i-bp-guide" />,
							label: "Terms of Service",
							href: TERMS_OF_SERVICE_URL,
						},
						{
							type: "link",
							icon: <i class="inline-block i-bp-shield" />,
							label: "Privacy Policy",
							href: PRIVACY_POLICY_URL,
						},
					]}
				/>
				<Suspense>
					<Show when={appInfo()}>
						{(appInfo) => (
							<div class="text-center prose-2xs text-secondary">
								Version: {appInfo().version} - Build {appInfo().build}
							</div>
						)}
					</Show>
				</Suspense>
			</div>
		</div>
	);
};

type MenuSectionProps = {
	title: JSX.Element;
	items: ({
		icon: JSX.Element;
		label: JSX.Element;
	} & (
		| {
				type: "link";
				href: string;
		  }
		| {
				type: "action";
				external?: boolean;
				onClick: () => void;
		  }
	))[];
};

const MenuSection = (props: MenuSectionProps) => {
	return (
		<div class="flex flex-col gap-10px">
			<h2 class="text-secondary prose-sm-b">{props.title}</h2>
			<div class="flex flex-col text-primary">
				<For each={props.items}>
					{(item) => (
						<Dynamic
							component={
								{
									link: WebLink,
									action: "button",
								}[item.type]
							}
							class="flex gap-12px items-center py-10px"
							href={item.type === "link" ? item.href : undefined}
							type={item.type === "action" ? "button" : undefined}
							onClick={item.type === "action" ? item.onClick : undefined}
						>
							<div class="size-24px [&>i]:size-full">{item.icon}</div>
							<div class="flex-1 prose-sm text-left">{item.label}</div>
							<Switch>
								<Match when={item.type === "link" || item.type === "action"}>
									<i
										class={cn(
											"inline-block i-bp-chevron-right",
											item.type === "link" ||
												(item.type === "action" && item.external)
												? "text-secondary"
												: "text-tertiary",
										)}
									/>
								</Match>
							</Switch>
						</Dynamic>
					)}
				</For>
			</div>
		</div>
	);
};

export default ProfileActivity;
