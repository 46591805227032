import { Big } from "big.js";
import { initGraphQLTada } from "gql.tada";
import type { introspection } from "./__generated__/env.d.ts";

export const graphql = initGraphQLTada<{
	introspection: introspection;
	scalars: {
		BigDecimal: BigDecimal;
		BigUint: BigUint;
		PlainDate: string;
		DateTime: string;
		URL: string;
	};
}>();

const Brand = Symbol("Brand");

const BigDecimal = Symbol("BigDecimal");
type BigDecimal = { [Brand]: typeof BigDecimal };
export const big = (input: BigDecimal | Big.BigSource) =>
	new Big(input as Big.BigSource);

const BigUint = Symbol("BigUint");
type BigUint = { [Brand]: typeof BigUint };
export const n = (input: BigUint) => BigInt(input as unknown as string);

export type { FragmentOf, ResultOf, VariablesOf } from "gql.tada";
export { readFragment } from "gql.tada";
