import { AppScreen } from "@contentstech/stackflow-plugin-basic-ui/solid";
import type { JSX } from "solid-js";
import { cn } from "~/lib/utils";

interface Props {
	class?: string;
	children: JSX.Element;
}

export const AuthAppScreen = (props: Props) => {
	return (
		<AppScreen>
			<div
				class={cn(
					"relative min-w-full h-100dvh flex flex-col overflow-y-auto text-center",
					"env-mobile:(bg-[linear-gradient(180deg,rgba(222,245,79,0)_50%,rgba(222,245,79,0.6)_100%)])",
					"env-desktop:(bg-[linear-gradient(180deg,rgba(222,245,79,0)_0%,rgba(222,245,79,0.4)_100%)])",
					props.class,
				)}
				style={{
					"background-size": "100vw 100dvh",
				}}
			>
				{props.children}
			</div>
		</AppScreen>
	);
};
