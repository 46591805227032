import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { onCleanup } from "solid-js";
import {
	type JSX,
	createContext,
	createSignal,
	onMount,
	useContext,
} from "solid-js";

const Context = createContext({
	height: () => 0 as number,
});

export const useKeyboard = () => useContext(Context);

export const KeyboardProvider = (props: { children: JSX.Element }) => {
	const [height, setHeight] = createSignal(0);

	onMount(() => {
		if (!Capacitor.isNativePlatform()) return;

		const didShowHandle = Keyboard.addListener("keyboardDidShow", (info) => {
			setHeight(info.keyboardHeight);
		});
		const willHideHandle = Keyboard.addListener("keyboardWillHide", () => {
			setHeight(0);
		});
		onCleanup(() => {
			Promise.all([didShowHandle, willHideHandle]).then((a) =>
				a.map((h) => h.remove()),
			);
		});
	});

	return (
		<Context.Provider value={{ height }}>{props.children}</Context.Provider>
	);
};
