import type { ValidComponent } from "solid-js";
import { splitProps } from "solid-js";

import type { PolymorphicProps } from "@kobalte/core/polymorphic";
import * as SkeletonPrimitive from "@kobalte/core/skeleton";

import { cn } from "~/lib/utils";

type SkeletonRootProps<T extends ValidComponent = "div"> = Omit<
	SkeletonPrimitive.SkeletonRootProps<T>,
	"animate" | "radius" | "width" | "height"
> & {
	class?: string | undefined;
	width?: string | number | undefined;
	height?: string | number | undefined;
};

const Skeleton = <T extends ValidComponent = "div">(
	props: PolymorphicProps<T, SkeletonRootProps<T>>,
) => {
	const [local, others] = splitProps(props as SkeletonRootProps, [
		"class",
		"circle",
		"width",
		"height",
	]);
	return (
		<SkeletonPrimitive.Root
			class={cn("bg-skeleton animate-skeleton", local.class)}
			style={{
				"background-size": "300%",
				width:
					typeof local.width === "number"
						? `${local.width}px`
						: (local.width ?? "100%"),
				height:
					typeof local.height === "number"
						? `${local.height}px`
						: (local.height ?? "100%"),
			}}
			radius={local.circle ? undefined : 6}
			circle={local.circle}
			animate
			{...others}
		/>
	);
};

export { Skeleton };
