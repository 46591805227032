import type { Component, ComponentProps, JSX, ValidComponent } from "solid-js";
import { Show, splitProps } from "solid-js";

import { Button as RawButton } from "@kobalte/core/button";
import * as DialogPrimitive from "@kobalte/core/dialog";
import type { PolymorphicProps } from "@kobalte/core/polymorphic";

import { cn } from "~/lib/utils";

const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal: Component<DialogPrimitive.DialogPortalProps> = (props) => {
	const [, rest] = splitProps(props, ["children"]);
	return (
		<DialogPrimitive.Portal {...rest}>
			<div class="fixed inset-0 z-50 flex flex-col items-center justify-center">
				{props.children}
			</div>
		</DialogPrimitive.Portal>
	);
};

type DialogOverlayProps<T extends ValidComponent = "div"> =
	DialogPrimitive.DialogOverlayProps<T> & { class?: string | undefined };

const DialogOverlay = <T extends ValidComponent = "div">(
	props: PolymorphicProps<T, DialogOverlayProps<T>>,
) => {
	const [, rest] = splitProps(props as DialogOverlayProps, ["class"]);
	return (
		<DialogPrimitive.Overlay
			class={cn(
				"fixed inset-0 z-50 bg-black/40 data-[expanded]:animate-in data-[closed]:animate-out data-[closed]:fade-out-0 data-[expanded]:fade-in-0",
				props.class,
			)}
			{...rest}
		/>
	);
};

type DialogContentProps<T extends ValidComponent = "div"> =
	DialogPrimitive.DialogContentProps<T> & {
		class?: string | undefined;
		children?: JSX.Element;
	};

const DialogContent = <T extends ValidComponent = "div">(
	props: PolymorphicProps<T, DialogContentProps<T>>,
) => {
	const [, rest] = splitProps(props as DialogContentProps, [
		"class",
		"children",
	]);
	return (
		<DialogPortal>
			<DialogOverlay />
			<DialogPrimitive.Content
				class={cn(
					"mx-7 z-50 grid transform-origin-center bg-white rounded-20px text-secondary duration-200",
					"env-mobile:(p-5 gap-2 prose-body text-center)",
					"env-desktop:(p-24px gap-16px max-w-480px leading-[1.4] font-15px rounded-10px)",
					"data-[expanded]:(animate-in fade-in-0 zoom-in-95)",
					"data-[closed]:(animate-out fade-out-0 zoom-out-95)",
					props.class,
				)}
				{...rest}
			>
				{props.children}
			</DialogPrimitive.Content>
		</DialogPortal>
	);
};

const DialogHeader: Component<
	ComponentProps<"div"> & { onClose?: () => void }
> = (props) => {
	const [locals, rest] = splitProps(props, ["class", "children", "onClose"]);
	return (
		<div
			class={cn(
				"flex text-center",
				"env-mobile:flex-col",
				"env-desktop:(flex-row justify-between items-center gap-22px)",
				locals.class,
			)}
			{...rest}
		>
			{locals.children}
			<Show when={locals.onClose}>
				<RawButton
					class="cursor-pointer p-8px hover:bg-background-dark line-height-0"
					onClick={() => locals.onClose?.()}
				>
					<i class="inline-block i-bp-close text-primary" />
				</RawButton>
			</Show>
		</div>
	);
};

const DialogFooter: Component<ComponentProps<"div">> = (props) => {
	const [, rest] = splitProps(props, ["class"]);
	return (
		<div
			class={cn(
				"flex gap-16px",
				"env-mobile:(flex-col mt-12px)",
				"env-desktop:(flex-row-reverse justify-start items-center mt-10px)",
				props.class,
			)}
			{...rest}
		/>
	);
};

type DialogTitleProps<T extends ValidComponent = "h2"> =
	DialogPrimitive.DialogTitleProps<T> & {
		class?: string | undefined;
	};

const DialogTitle = <T extends ValidComponent = "h2">(
	props: PolymorphicProps<T, DialogTitleProps<T>>,
) => {
	const [, rest] = splitProps(props as DialogTitleProps, ["class"]);
	return (
		<DialogPrimitive.Title
			class={cn(
				"text-primary",
				"env-mobile:prose-md-b",
				"env-desktop:(prose-unbounded-xl text-left)",
				props.class,
			)}
			{...rest}
		/>
	);
};

export {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogHeader,
	DialogFooter,
	DialogTitle,
};
