import { type VariantProps, cva } from "class-variance-authority";
import { For, type JSX, createContext, onMount, useContext } from "solid-js";
import { createStore, produce } from "solid-js/store";
import { cn } from "~/lib/utils";

const variants = cva(
	"fixed top-0 inset-x-0 z-50 pt-[var(--safe-area-inset-top)] transition-transform duration-500",
	{
		variants: {
			variant: {
				success: "bg-functional-green-dark text-white",
				error: "bg-functional-red text-white",
			},
		},
		defaultVariants: {
			variant: "success",
		},
	},
);

export type TopAlertOptions = VariantProps<typeof variants> & {
	children: JSX.Element;
	duration?: number;
};
type TopAlertProps = TopAlertOptions & {
	open: boolean;
	onMount?: () => void;
	onExpired?: () => void;
	onClosed?: () => void;
};

const TopAlert = (props: TopAlertProps) => {
	let ref: HTMLDivElement | undefined;

	onMount(() => {
		props.onMount?.();
		setTimeout(() => {
			ref?.addEventListener(
				"transitionend",
				() => {
					props.onClosed?.();
				},
				{ once: true },
			);
			props.onExpired?.();
		}, props.duration ?? 2500);
	});

	return (
		<div
			ref={ref}
			class={cn(
				variants({ variant: props.variant }),
				props.open ? "translate-y-0" : "-translate-y-full",
			)}
		>
			<div class="px-10px py-8px text-center prose-sm-b">{props.children}</div>
		</div>
	);
};

const Context = createContext({
	open: (_options: TopAlertOptions) => {},
});

export const useTopAlert = () => useContext(Context);

export const TopAlertProvider = (props: { children: JSX.Element }) => {
	const [alerts, setAlerts] = createStore<
		{
			options: TopAlertOptions;
			open: boolean;
		}[]
	>([]);

	return (
		<Context.Provider
			value={{
				open: (options) => {
					setAlerts(
						produce((draft) => {
							draft.push({
								open: false,
								options,
							});
						}),
					);
				},
			}}
		>
			<For each={alerts}>
				{(alert, i) => (
					<TopAlert
						{...alert.options}
						open={alert.open}
						onMount={() =>
							setTimeout(() =>
								setAlerts(
									produce((draft) => {
										draft[i()]!.open = true;
									}),
								),
							)
						}
						onExpired={() =>
							setAlerts(
								produce((draft) => {
									draft[i()]!.open = false;
								}),
							)
						}
						onClosed={() => {
							setAlerts(
								produce((draft) => {
									draft.splice(i(), 1);
								}),
							);
						}}
					/>
				)}
			</For>
			{props.children}
		</Context.Provider>
	);
};
