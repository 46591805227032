import type { Component, ComponentProps, ValidComponent } from "solid-js";
import { Show, splitProps } from "solid-js";

import type { DynamicProps, RootProps } from "@corvu/otp-field";
import OtpField from "@corvu/otp-field";

import { cn } from "~/lib/utils";

type OTPFieldProps<T extends ValidComponent = "div"> = RootProps<T> & {
	class?: string;
};

const OTPField = <T extends ValidComponent = "div">(
	props: DynamicProps<T, OTPFieldProps<T>>,
) => {
	const [local, others] = splitProps(props as OTPFieldProps, ["class"]);
	return (
		<OtpField
			class={cn(
				"flex items-center gap-2 disabled:cursor-not-allowed has-[:disabled]:opacity-50",
				local.class,
			)}
			{...others}
		/>
	);
};

const OTPFieldInput = OtpField.Input;

const OTPFieldGroup: Component<ComponentProps<"div">> = (props) => {
	const [local, others] = splitProps(props, ["class"]);
	return <div class={cn("flex items-center", local.class)} {...others} />;
};

const OTPFieldSlot: Component<ComponentProps<"div"> & { index: number }> = (
	props,
) => {
	const [local, others] = splitProps(props, ["class", "index"]);
	const context = OtpField.useContext();
	const char = () => context.value()[local.index];
	const showFakeCaret = () =>
		context.value().length === local.index && context.isInserting();

	return (
		<div
			class={cn(
				"group relative flex size-12 items-center justify-center border-b border-primary text-primary prose-xl-b",
				local.class,
			)}
			{...others}
		>
			{char()}
			<Show when={showFakeCaret()}>
				<div class="pointer-events-none absolute inset-0 flex items-center justify-center">
					<div class="h-30px w-2px animate-blink bg-#0057FF" />
				</div>
			</Show>
		</div>
	);
};

export { OTPField, OTPFieldInput, OTPFieldGroup, OTPFieldSlot };
