import { createElementSize } from "@solid-primitives/resize-observer";
import {
	type JSX,
	type Setter,
	createRenderEffect,
	createSignal,
	splitProps,
} from "solid-js";
import { cn } from "~/lib/utils";

type StickyHeaderContainerProps = Omit<
	JSX.HTMLAttributes<HTMLDivElement>,
	"onScroll"
> & {
	header: (hiddenAreaRef: Setter<HTMLElement | undefined>) => JSX.Element;
	headerWrapperRef?: JSX.HTMLAttributes<HTMLDivElement>["ref"];
	children: JSX.Element;
};

export const StickyHeaderContainer = (props: StickyHeaderContainerProps) => {
	const [locals, others] = splitProps(props, [
		"class",
		"header",
		"headerWrapperRef",
		"children",
	]);

	const [scrollDetector, setScrollDetector] = createSignal<HTMLElement>();
	const [bottomDetector, setBottomDetector] = createSignal<HTMLElement>();
	const [hiddenArea, setHiddenArea] = createSignal<HTMLElement>();
	const hiddenAreaSize = createElementSize(hiddenArea);

	const [scrollTop, setScrollTop] = createSignal(0);
	const [stickyOffset, setStickyOffset] = createSignal(0);

	createRenderEffect((prevScrollTop: number) => {
		const currScrollTop = scrollTop();
		const currStickyOffset = stickyOffset();
		const delta = currScrollTop - prevScrollTop;
		setStickyOffset(
			Math.max(
				-(hiddenAreaSize.height ?? 0),
				Math.min(0, currStickyOffset - delta),
			),
		);
		return currScrollTop;
	}, 0);

	return (
		<div
			class={cn(
				"absolute inset-0 overflow-x-hidden overflow-y-auto no-scrollbar",
				locals.class,
			)}
			onScroll={() => {
				const scrollDetectorEl = scrollDetector();
				const bottomDetectorEl = bottomDetector();
				if (scrollDetectorEl && visualViewport && bottomDetectorEl) {
					setScrollTop(
						// ignore overscroll
						Math.max(-scrollDetectorEl.getBoundingClientRect().top, 0) -
							Math.max(
								window.screen.height -
									bottomDetectorEl.getBoundingClientRect().bottom,
								0,
							),
					);
				}
			}}
			{...others}
		>
			<div ref={setScrollDetector} />
			<div
				ref={locals.headerWrapperRef}
				class="bg-background sticky top-[var(--safe-area-inset-top)] z-50"
				style={{ transform: `translateY(${stickyOffset()}px)` }}
			>
				{locals.header(setHiddenArea)}
			</div>
			<div class="bg-background fixed inset-x-0 top-0 h-[var(--safe-area-inset-top)] z-100" />
			{locals.children}
			<div ref={setBottomDetector} />
		</div>
	);
};
