// @unocss-include
import type { graphql } from "~/lib/gql/tada";

// NOTE: Order of these moods will be used in the UI. Do not change.
export const Moods: Record<
	Mood,
	{ title: string; color: MoodColor; icon: string }
> = {
	HAPPY: {
		title: "Happy",
		color: "lime",
		icon: "i-bp-mood-happy",
	},
	DREAMY: {
		title: "Dreamy",
		color: "skyblue",
		icon: "i-bp-mood-dreamy",
	},
	RELAXED: {
		title: "Relaxed",
		color: "applegreen",
		icon: "i-bp-mood-relaxed",
	},
	EUPHORIC: {
		title: "Euphoric",
		color: "lime",
		icon: "i-bp-mood-euphoric",
	},
	QUIRKY: {
		title: "Quirky",
		color: "lime",
		icon: "i-bp-mood-quirky",
	},
	SUSPENSE: {
		title: "Suspense",
		color: "red",
		icon: "i-bp-mood-suspense",
	},
	MYSTERIOUS: {
		title: "Mysterious",
		color: "skyblue",
		icon: "i-bp-mood-mysterious",
	},
	SENTIMENTAL: {
		title: "Sentimental",
		color: "tangerine",
		icon: "i-bp-mood-sentimental",
	},
	SAD: {
		title: "Sad",
		color: "skyblue",
		icon: "i-bp-mood-sad",
	},
	DARK: {
		title: "Dark",
		color: "red",
		icon: "i-bp-mood-dark",
	},
	ANGRY: {
		title: "Angry",
		color: "red",
		icon: "i-bp-mood-angry",
	},
	PEACEFUL: {
		title: "Peaceful",
		color: "applegreen",
		icon: "i-bp-mood-peaceful",
	},
	SCARY: {
		title: "Scary",
		color: "red",
		icon: "i-bp-mood-scary",
	},
	FUNNY: {
		title: "Funny",
		color: "lime",
		icon: "i-bp-mood-funny",
	},
	ROMANTIC: {
		title: "Romantic",
		color: "tangerine",
		icon: "i-bp-mood-romantic",
	},
	JAZZY: {
		title: "Jazzy",
		color: "tangerine",
		icon: "i-bp-mood-jazzy",
	},
};
export type Mood = ReturnType<typeof graphql.scalar<"Mood">>;

export const MoodColors = {
	applegreen: "rgb(var(--brand-applegreen))",
	tangerine: "rgb(var(--brand-tangerine))",
	lime: "rgb(var(--brand-lime))",
	skyblue: "rgb(var(--brand-skyblue))",
	red: "rgb(var(--functional-red))",
};
export type MoodColor = keyof typeof MoodColors;
