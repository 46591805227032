import { AppScreen } from "@contentstech/stackflow-plugin-basic-ui/solid";
import { useChild, useParent } from "@contentstech/stackflow-plugin-omniflow";
import {
	type ActivityComponentType,
	useFlow,
} from "@contentstech/stackflow-solid/future";
import { Tabs } from "@kobalte/core/tabs";
import type { RegisteredActivityName } from "@stackflow/config";
import { Dynamic } from "solid-js/web";
import { Passthrough } from "~/components/passthrough";
import { AppBar } from "~/components/ui/appbar";

declare module "@stackflow/config" {
	interface Register {
		SettingsActivity: Record<string, never>;
	}
}

const AVAILABLE_TABS = [
	{
		label: "Account",
		value: "AccountSettingsActivity",
	},
	{
		label: "Payout settings",
		value: "PayoutSettingsActivity",
	},
] as const;

const SettingsActivity: ActivityComponentType<"SettingsActivity"> = () => {
	const actions = useFlow();
	const child = useChild();
	const parent = useParent();

	return (
		<Dynamic component={parent ? Passthrough : AppScreen}>
			{/* TODO: It would be better to apply class to Appscreen for better control */}
			<AppBar title="Settings" class="env-desktop:(mb-16px)" />
			<div class="env-desktop:(px-32px)">
				<Tabs
					value={child?.()?.name}
					onChange={(value) =>
						actions.replace(value as RegisteredActivityName, {})
					}
					class="mt-16px"
				>
					<Tabs.List class="flex border-b border-border-light relative mx-[-16px]">
						{AVAILABLE_TABS.map((tab) => (
							<Tabs.Trigger
								value={tab.value}
								class="mx-4 py-12px prose-md-b text-tertiary data-[selected]:text-primary hover:text-secondary focus:outline-none"
							>
								{tab.label}
							</Tabs.Trigger>
						))}
						<Tabs.Indicator class="absolute bottom-0 bg-blue-600 transition-all duration-300 bg-primary h-2px" />
					</Tabs.List>
					<div class="pt-32px relative">{child?.()?.render()}</div>
				</Tabs>
			</div>
		</Dynamic>
	);
};

export default SettingsActivity;
