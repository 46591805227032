import type { ActivityComponentType } from "@contentstech/stackflow-solid/future";
import Tooltip from "@corvu/tooltip";
import { Button as RawButton } from "@kobalte/core/button";
import { RadioGroup } from "@kobalte/core/radio-group";
import { Select } from "@kobalte/core/select";
import { Tooltip as DashboardTooltip, type FontSpec } from "chart.js";
import { on } from "solid-js";
import {
	For,
	type JSX,
	Match,
	Show,
	Suspense,
	Switch,
	createEffect,
	createMemo,
	createRenderEffect,
	createSignal,
	mapArray,
	onCleanup,
	onMount,
	useTransition,
} from "solid-js";
import { Intl, Temporal } from "temporal-polyfill";
import { match } from "ts-pattern";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import { Divider } from "~/components/ui/divider";
import {
	Drawer,
	DrawerContent,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "~/components/ui/drawer";
import { Image } from "~/components/ui/image";
import { Skeleton } from "~/components/ui/skeleton";
import { useAddChannel } from "~/lib/actions/addChannel";
import { Chart } from "~/lib/chart";
import { getColor } from "~/lib/colors";
import { createDebounced } from "~/lib/createDebounced";
import { createDeferredValue } from "~/lib/createDeferredValue";
import { environment } from "~/lib/environment";
import { createToeQuery } from "~/lib/gql/createToeQuery";
import { type FragmentOf, graphql, readFragment } from "~/lib/gql/tada";
import { latest } from "~/lib/latest";
import { cn } from "~/lib/utils";

declare module "@stackflow/config" {
	interface Register {
		DashboardActivity: Record<string, never>;
	}
}

type ChannelInfoProps = {
	image: JSX.Element;
	title: JSX.Element;
};

const AddChannel = () => {
	const addChannel = useAddChannel();

	return (
		<RawButton class="flex gap-4 items-center w-fit" onClick={addChannel}>
			<div class="flex items-center justify-center size-70px rounded-full overflow-hidden bg-highlighted">
				<i class="inline-block i-bp-add" />
			</div>
			<div class="prose-md text-primary">Add channel</div>
		</RawButton>
	);
};

const ChannelInfo = (props: ChannelInfoProps) => (
	<div class="flex gap-16px items-center env-desktop:(gap-12px)">
		<div class="size-70px rounded-full overflow-hidden env-desktop:(size-48px)">
			{props.image}
		</div>
		<div class="prose-md text-primary">{props.title}</div>
	</div>
);

type ChannelListProps = {
	$channels: FragmentOf<typeof ChannelListFragment>[];
	activeChannelId: string | undefined;
	onChannelClick: (channelId: string) => void;
};

const ChannelListFragment = graphql(`
	fragment ChannelList on Channel {
		id
		name
		profileImage
	}
`);

const ChannelList = (props: ChannelListProps) => {
	const channels = mapArray(
		() => props.$channels,
		($channel) => readFragment(ChannelListFragment, $channel),
	);

	return (
		<div class="flex gap-6 items-stretch">
			<For each={channels()}>
				{(channel) => (
					<RawButton
						class="flex flex-col items-center gap-6px transition-opacity"
						classList={{ "opacity-50": channel.id !== props.activeChannelId }}
						onClick={() => props.onChannelClick(channel.id)}
					>
						<div
							class={cn(
								"relative size-54px overflow-hidden border border-2 border-transparent p-1px rounded-full transition-colors",
								channel.id === props.activeChannelId && "border-primary",
							)}
						>
							<Image
								src={channel.profileImage!}
								class="rounded-full"
								alt={`Channel image of ${channel.name}`}
							/>
						</div>
						<div
							class={cn(
								"prose-xs",
								channel.id === props.activeChannelId
									? "text-primary"
									: "text-secondary",
							)}
						>
							{channel.name}
						</div>
					</RawButton>
				)}
			</For>
		</div>
	);
};

type MetricChartProps = {
	range: PlainDateRange;
	data: number[];
	class?: string;
};

type PlainDateRange = {
	from: Temporal.PlainDate;
	to: Temporal.PlainDate;
};

const MetricChart = (props: MetricChartProps) => {
	let ref: HTMLCanvasElement | undefined;
	const [chart, setChart] = createSignal<Chart | undefined>(undefined);

	const data = createMemo(() => {
		return {
			labels: props.data.map((_, i) =>
				durationFormat.format(props.range.from.add({ days: i })),
			),
			datasets: [{ data: [...props.data] }],
		};
	});

	const chartFont: Partial<FontSpec> = {
		family: "Inter Variable",
		size: 13,
		weight: "normal",
		lineHeight: 1.2,
	};

	onMount(() => {
		const initChart = () => {
			if (!ref?.ownerDocument?.defaultView) return;
			const chart = new Chart(ref, {
				type: "line",
				data: data(),
				options: {
					clip: false,
					// @ts-expect-error: why is this not in the types?
					pointRadius: environment === "mobile" ? 2 : 4,
					borderColor: getColor("primary", "raw"),
					borderWidth: 2,
					responsive: true,
					maintainAspectRatio: false,
					backgroundColor: getColor("brand-lime", "raw"),
					interaction: {
						intersect: false,
						mode: "index",
					},
					scales: {
						x: {
							ticks: {
								source: "labels",
								callback(value, index, ticks) {
									if (
										index !== 0 &&
										index !== ticks.length - 1 &&
										environment === "mobile"
									)
										return "";

									return this.getLabelForValue(index);
								},
								color:
									environment === "desktop"
										? getColor("tertiary", "raw")
										: undefined,
							},
							grid:
								environment === "desktop"
									? {
											drawOnChartArea: false,
											color: "#000",
										}
									: {
											drawTicks: false,
										},
							border: {
								width: 1,
								color: getColor("primary", "raw"),
							},
						},

						y: {
							min: 0,
							grid: {
								lineWidth: 1,
								color: getColor("border-dark", "raw"),
								drawTicks: false,
							},
							ticks: {
								callback: (value, index, _ticks) => {
									if (index % 2 !== 0) return;
									return value;
								},
							},
							axis: "y",
							border:
								environment === "desktop"
									? {
											dash: [2, 2],
											dashOffset: 0,
											display: false,
										}
									: {},
						},
					},
					animation: {
						duration: 500,
					},
					plugins: {
						tooltip: {
							position: "nearest",
							displayColors: false,
							backgroundColor: "rgb(0 0 0 / 0.7)",
							titleFont: chartFont,
							bodyFont: chartFont,
						},
						customCanvasBackgroundColor: {
							color: environment === "desktop" ? "white" : "transparent",
						},
					},
					...(environment === "desktop"
						? {
								layout: {
									padding: {
										top: 40,
										bottom: 40,
										left: 28,
										right: 28,
									},
								},
							}
						: {}),
				},
				plugins: [DashboardTooltip],
			});
			setChart(chart);
		};
		setTimeout(initChart, 1000);
	});

	createEffect(
		on(
			() => data(),
			() => {
				const c = chart();
				if (!c) return;
				c.data.labels = data().labels;
				for (let i = 0; i < c.data.datasets.length; i++) {
					if (!data().datasets[i]) break;
					c.data.datasets[i]!.data = data().datasets[i]!.data;
				}
				c.update();
			},
			{ defer: true },
		),
	);

	onCleanup(() => chart()?.destroy());

	return <canvas ref={ref} class={cn("h-full", props.class)} />;
};
// TODO: Factor out the metric info to a separate component
const DayRangeSelector = (props: {
	range: number[];
	value: number;
	onChange: (value: number | null) => void;
}) => {
	return (
		<Select
			options={props.range}
			defaultValue={props.range[0]}
			disallowEmptySelection
			value={props.value}
			placement="bottom-end"
			onChange={(v) => props.onChange(v)}
			itemComponent={(_props) => (
				<Select.Item
					item={_props.item}
					class="text-4 flex items-center justify-between relative select-none outline-none px-16px py-12px"
				>
					<Select.ItemLabel>{_props.item.rawValue} days</Select.ItemLabel>
					<i
						class={cn(
							"inline-block size-24px",
							_props.item.rawValue === props.value
								? "i-bp-radio-selected"
								: "i-bp-radio text-border-light",
						)}
					/>
				</Select.Item>
			)}
		>
			<Select.Trigger class="flex rounded-full px-4 py-9px prose-sm-b bg-background-off-white text-primary transition-colors outline-none focus:outline-none data-[checked]:(bg-primary text-white)">
				<Select.Value>
					{(state) => `${state.selectedOption()} days`}
				</Select.Value>
				<Select.Icon class="ml-2 inline-flex">
					<i class="inline-block i-bp-chevron-down" />
				</Select.Icon>
			</Select.Trigger>
			<Select.Portal>
				<Select.Content class="bg-white rounded-12px border-1px z-9999 w-180px shadow-[0px_4px_12px_0px_rgba(0,0,0,0.15)]">
					<Select.Listbox class="overflow-y-auto max-h-360px py-8px" />
				</Select.Content>
			</Select.Portal>
		</Select>
	);
};
// TODO: Factor out the metric info to a separate component
const MetricInfo = (props: {
	dataRangeText: string;
}) => {
	const content = () => (
		<div class="flex flex-col gap-5 env-desktop:(gap-4)">
			<For each={Object.values(KeyMetrics)}>
				{(info) => (
					<div class="flex flex-col gap-1 env-desktop:(gap-1.5)">
						<h3 class="text-primary prose-sm-b">{info.title}</h3>
						<p class="text-secondary prose-xs env-desktop:(leading-[1.4])">
							{info.description(props.dataRangeText)}
						</p>
					</div>
				)}
			</For>
			<Show when={environment === "mobile"}>
				<Divider size="full" class="mx-[-1.5rem]" />
			</Show>
			<div class="flex flex-row gap-10px ">
				<i class="flex-shrink-0 i-bp-info size-16px text-functional-blue" />
				<p class="text-functional-blue prose-2xs leading-[1.3]">
					Views and earnings from YouTube Shorts are reported based on data from
					two days prior (D-2). This means the numbers reflect activity up to
					two days ago, and the final payout may differ slightly from the
					estimated earnings displayed, as updates and adjustments could occur.
				</p>
			</div>
		</div>
	);
	return environment === "mobile" ? (
		<Drawer>
			<DrawerTrigger class="flex items-center">
				<i class="inline-block i-bp-info text-tertiary" />
			</DrawerTrigger>
			<DrawerContent>
				<DrawerHeader>
					<DrawerTitle>Key metrics</DrawerTitle>
				</DrawerHeader>
				<div class="px-4 py-6 ">{content()}</div>
			</DrawerContent>
		</Drawer>
	) : (
		<Tooltip
			placement="bottom-start"
			openDelay={100}
			closeOnPointerDown={false}
			floatingOptions={{
				offset: 13,
				shift: true,
				size: {
					fitViewPort: true,
				},
			}}
		>
			<Tooltip.Anchor class="flex justify-center items-center">
				<Tooltip.Trigger class="flex">
					<i class="inline-block i-bp-info size-16px text-tertiary" />
				</Tooltip.Trigger>
			</Tooltip.Anchor>
			<Tooltip.Portal>
				<Tooltip.Content class="relative rounded-12px bg-white z-999 w-420px shadow-[0px_4px_12px_0px_rgba(0,0,0,0.15)] max-h-200px overflow-y-auto">
					<div class="sticky top-0 bg-white px-20px pt-20px">
						<h2 class="text-primary prose-md-b">Key metrics</h2>
						<Divider size="full" class="mr-6 my-4" />
					</div>
					<div class="px-20px pb-20px">{content()}</div>
				</Tooltip.Content>
			</Tooltip.Portal>
		</Tooltip>
	);
};

type LayoutProps = {
	appBarActions?: JSX.Element[];
	channels: JSX.Element;
	divider?: JSX.Element;
	rangeSelector: JSX.Element;
	chartTitle: JSX.Element;
	metricSummaries: JSX.Element[];
	chart: JSX.Element;

	metricSummaryValue?: string;
	onMetricSummaryChange?: (metric: string) => void;
};

const Layout = (props: LayoutProps) => {
	return (
		<div class="flex flex-col gap-16px env-desktop:mt-16px">
			<div class="env-mobile:px-16px env-desktop:px-32px">{props.channels}</div>
			<Show when={props.divider} fallback={<Divider size="full" />}>
				{props.divider}
			</Show>
			<div class="env-mobile:px-16px env-desktop:px-32px flex flex-col gap-4 env-desktop:(max-w-1080px)">
				<div class="flex flex-col gap-4 env-desktop:(flex-row-reverse)">
					{/* TODO: Fill this mock container with the actual component (Analytics/Content) */}
					{props.rangeSelector}
					{props.chartTitle}
				</div>
				<RadioGroup
					value={props.metricSummaryValue}
					onChange={props.onMetricSummaryChange}
					class="grid grid-cols-2 gap-2 env-desktop:(grid-cols-3)"
				>
					<For each={props.metricSummaries}>
						{(summary) => (
							<div class="h-80px env-desktop:(h-96px)">{summary}</div>
						)}
					</For>
				</RadioGroup>
				<div class="h-190px env-desktop:(h-274px)">{props.chart}</div>
			</div>
		</div>
	);
};

// TODO: Update descriptions
const _KeyMetrics = {
	totalViews: {
		title: "Total Views",
		description: (duration) =>
			`The number of people who viewed your shorts compared to ${duration}`,
		defaultValue: 0,
		formatValue: (v) => `${v.toLocaleString()}`,
	},
	premiumSubscriberViews: {
		title: "Premium subscriber's views",
		shortTitle: "Premium sub. views",
		description: (duration) =>
			`The number of YouTube Premium subscribers who viewed your shorts compared to ${duration}`,
		defaultValue: 0,
		formatValue: (v) => `${v.toLocaleString()}`,
	},
	estimatedPayouts: {
		title: "Estimated payouts",
		shortTitle: "Est. payouts",
		description: (duration) =>
			`The estimated earning from your shorts, based on total views and the percentage of views from YouTube Premium subscribers compared to ${duration}`,
		defaultValue: 0,
		formatValue: (v) =>
			v.toLocaleString(undefined, {
				style: "currency",
				currency: "USD",
				currencyDisplay: "narrowSymbol",
			}),
	},
	rpm: {
		title: "RPM",
		description: (duration) =>
			`The estimated earnings per 1,000 views compared to the period from ${duration}`,
		formatValue: (v) =>
			v.toLocaleString(undefined, {
				style: "currency",
				currency: "USD",
				currencyDisplay: "narrowSymbol",
				maximumFractionDigits: 5,
			}),
		defaultValue: 0,
	},
	totalWatchTime: {
		title: "Total watch time",
		description: (duration) =>
			`The total amount of time viewers spent watching your YouTube Shorts compared to ${duration}`,
		defaultValue: 0,
		formatValue: (v) => {
			const duration = Temporal.Duration.from({
				seconds: Math.round(v),
			}).round({ largestUnit: "hour" });

			if (duration.hours >= 10000) {
				return `${duration.hours.toLocaleString()} hours`;
			}

			return new globalThis.Intl.DurationFormat("en", {
				style: "digital",
				// @ts-expect-error Intl.DurationFormat is not fully supported in TypeScript
				hours: "2-digit",
				hoursDisplay: "always",
			})
				.format(duration)
				.replace(/^:, /, ""); // NOTE: workaround Safari 18 bug;
		},
	},
	avgWatchTime: {
		title: "Average watch time",
		shortTitle: "Avg. watch time",
		description: (duration) =>
			`The average duration that each viewer spent watching your YouTube Shorts compared to ${duration}`,
		defaultValue: 0,
		formatValue: (v) =>
			new globalThis.Intl.DurationFormat("en", {
				style: "digital",
				hoursDisplay: "auto",
			})
				.format(
					Temporal.Duration.from({ seconds: Math.round(v) }).round({
						largestUnit: "hour",
					}),
				)
				.replace(/^:, /, ""), // NOTE: workaround Safari 18 bug
	},
} satisfies Record<string, DashboardMetricInfo>;
const KeyMetrics = _KeyMetrics as Record<DashboardMetric, DashboardMetricInfo>;

type DashboardMetric = keyof typeof _KeyMetrics;
type DashboardMetricInfo = {
	title: string;
	shortTitle?: string;
	description: (duration: string) => string;
	defaultValue: number;
	formatValue?: (value: number) => string;
};
const durationFormat = Intl.DateTimeFormat("en", {
	month: "short",
	day: "numeric",
});

const CurrentMetricsFragment = graphql(`
	fragment CurrentMetrics on ChannelMetrics {
		from
		to
		totalViews {
			aggregate {
				value: sum
			}
			daily
		}
		premiumSubscriberViews {
			aggregate {
				value: sum
			}
			daily
		}
		estimatedPayouts {
			aggregate {
				value: sum
			}
			daily
		}
		rpm {
			aggregate {
				value: mean
			}
			daily
		}
		totalWatchTime: duration {
			aggregate {
				value: sum
			}
			daily
		}
		avgWatchTime: duration {
			aggregate {
				value: mean
			}
			daily
		}
	}
`);

const PreviousMetricsFragment = graphql(`
	fragment PreviousMetrics on ChannelMetrics {
		totalViews {
			aggregate {
				value: sum
			}
		}
		premiumSubscriberViews {
			aggregate {
				value: sum
			}
		}
		estimatedPayouts {
			aggregate {
				value: sum
			}
		}
		rpm {
			aggregate {
				value: mean
			}
		}
		totalWatchTime: duration {
			aggregate {
				value: sum
			}
		}
		avgWatchTime: duration {
			aggregate {
				value: mean
			}
		}
	}
`);

const DashboardQuery = graphql.persisted(
	"sha256:491d7624f3931825dbf7145027a0c1d8c967927a2b26c3d9c35d66bca83e4730",
	graphql(
		`
			query Dashboard(
				$currentFilter: ChannelMetricsFilter!
				$previousFilter: ChannelMetricsFilter!
			) {
				ownedChannels {
					id
					name
					profileImage
					currentMetrics: metrics(filter: $currentFilter) {
						...CurrentMetrics
					}
					previousMetrics: metrics(filter: $previousFilter) {
						...PreviousMetrics
					}
					...ChannelList
				}
				dataAvailableUntil
			}
		`,
		[CurrentMetricsFragment, PreviousMetricsFragment, ChannelListFragment],
	),
);

const ChannelMetricsQuery = graphql.persisted(
	"sha256:975309ac8ab325b42c2fb85977d6b844507c53f11c619669458f8b494ddf8746",
	graphql(
		`
			query ChannelMetrics(
				$channelId: ID!
				$currentFilter: ChannelMetricsFilter!
				$previousFilter: ChannelMetricsFilter!
			) {
				node(id: $channelId) {
					__typename
					id
					... on Channel {
						currentMetrics: metrics(filter: $currentFilter) {
							...CurrentMetrics
						}
						previousMetrics: metrics(filter: $previousFilter) {
							...PreviousMetrics
						}
					}
				}
			}
		`,
		[CurrentMetricsFragment, PreviousMetricsFragment],
	),
);

const DashboardActivity: ActivityComponentType<"DashboardActivity"> = () => {
	const [activeChannelId, setActiveChannelId] = createSignal<string>();
	const [recentDays, setRecentDays] = createSignal(7);
	const [selectedMetric, setSelectedMetric] = createSignal<
		DashboardMetric | undefined
	>(undefined);
	const [isPending] = useTransition();
	const isPendingDebounced = createDebounced(isPending, 50);
	const addChannel = useAddChannel();

	const metricFilters = createDeferredValue(
		createMemo(() => ({
			currentFilter: { days: { count: recentDays(), offset: 0 } },
			previousFilter: { days: { count: recentDays(), offset: recentDays() } },
		})),
	);
	const [query] = createToeQuery({
		query: DashboardQuery,
		variables: metricFilters,
	});
	const [metricsQuery] = createToeQuery({
		query: ChannelMetricsQuery,
		variables: () => ({
			...metricFilters(),
			channelId: activeChannelId()!,
		}),
		// TODO: Fix 7 day metrics not updating without this. Should be debugged, maybe upstream issue?
		requestPolicy: "network-only",
		pause: () => !latest(() => query.data) || !activeChannelId(),
	});
	const activeChannel = () =>
		query.data?.ownedChannels.find((v) => v.id === activeChannelId());
	const activeMetrics = () => {
		const channel = match(metricsQuery.data?.node)
			.with({ __typename: "Channel" }, (v) => v)
			.otherwise(() => activeChannel());
		if (!channel) return;
		return {
			current: readFragment(CurrentMetricsFragment, channel.currentMetrics),
			previous: readFragment(PreviousMetricsFragment, channel.previousMetrics),
		};
	};

	createRenderEffect(() => {
		if (
			activeChannelId() == null &&
			(latest(() => query.data?.ownedChannels.length) ?? 0) > 0
		) {
			setActiveChannelId(query.data!.ownedChannels[0]!.id);
		}
	});

	const hasData = createMemo(() => {
		const data = latest(() => query.data);
		return data?.ownedChannels?.some(
			(c) =>
				Object.values(readFragment(CurrentMetricsFragment, c.currentMetrics))
					.filter((v) => typeof v === "object")
					.some((v) => v?.aggregate.value) ||
				Object.values(
					readFragment(PreviousMetricsFragment, c.previousMetrics),
				).some((v) => v?.aggregate.value),
		);
	});

	createEffect(() => {
		if (hasData()) setSelectedMetric("totalViews");
	});

	const dataDateRangeText = () => {
		const until = latest(() => query.data)?.dataAvailableUntil;
		if (until == null) return "";

		const to = Temporal.PlainDate.from(until);

		const last = Temporal.PlainDate.from(to).subtract({ days: 1 });
		const from = Temporal.PlainDate.from(to).subtract({ days: recentDays() });

		return durationFormat.formatRange(from, last).replace("–", "-");
	};

	return (
		<div class="flex flex-col">
			<AppBar
				title="Dashboard"
				actions={[
					<Suspense>
						<Show
							when={
								environment === "mobile" &&
								latest(() => query.data) &&
								!hasData()
							}
						>
							<RawButton class="px-4 py-6px -my-2 flex items-center gap-6px text-primary prose-xs-b rounded-full border-2 border-border-dark">
								Guide
								<i class="inline-block i-bp-next" />
							</RawButton>
						</Show>
						<Show when={environment === "desktop" && latest(() => query.data)}>
							<Button variant="highlighted" size="sm" onClick={addChannel}>
								Add channel
							</Button>
						</Show>
					</Suspense>,
				]}
			/>
			<Suspense
				fallback={
					<Layout
						channels={
							<ChannelInfo
								image={<Skeleton />}
								title={<Skeleton width={83} height={22} />}
							/>
						}
						divider={
							<div class="opacity-0">
								<Divider size="full" />
							</div>
						}
						rangeSelector={<Skeleton height={36} />}
						chartTitle={<Skeleton width={132} height={48} />}
						metricSummaries={Array(6)
							.fill(0)
							.map(() => <Skeleton />)}
						chart={<Skeleton height="calc(100% - 30px)" />}
					/>
				}
			>
				<Show when={query.data}>
					{(data) => (
						<Layout
							channels={
								<Switch>
									<Match
										when={
											environment === "desktop" &&
											data().ownedChannels.length === 0
										}
									>
										<div class="flex items-center prose-md text-primary text-primary gap-8px">
											<i class="inline-block i-bp-info text-primary size-16px" />
											Connect your channel now to get started
										</div>
									</Match>
									<Match
										when={
											environment === "mobile" &&
											data().ownedChannels.length === 0
										}
									>
										<AddChannel />
									</Match>
									<Match when={data().ownedChannels.length === 1}>
										<ChannelInfo
											image={
												<Image
													src={data().ownedChannels[0]!.profileImage}
													alt={`Channel image of ${data().ownedChannels[0]!.name}`}
												/>
											}
											title={data().ownedChannels[0]!.name}
										/>
									</Match>
									<Match when={data().ownedChannels.length > 1}>
										<ChannelList
											$channels={data().ownedChannels}
											activeChannelId={activeChannelId()}
											onChannelClick={setActiveChannelId}
										/>
									</Match>
								</Switch>
							}
							rangeSelector={
								environment === "mobile" ? (
									<RadioGroup
										value={recentDays().toString()}
										onChange={(v) => setRecentDays(Number(v))}
									>
										<div class="flex gap-6px">
											<For each={[7, 14, 30]}>
												{(days) => (
													<RadioGroup.Item value={days.toString()}>
														<RadioGroup.ItemInput />
														<RadioGroup.ItemControl class="rounded-full px-4 py-9px prose-sm-b bg-background-off-white text-primary transition-colors data-[checked]:(bg-primary text-white)">
															{days} days
														</RadioGroup.ItemControl>
													</RadioGroup.Item>
												)}
											</For>
										</div>
									</RadioGroup>
								) : (
									<DayRangeSelector
										range={[7, 14, 30]}
										value={recentDays()}
										onChange={setRecentDays}
									/>
								)
							}
							chartTitle={
								<div class="flex-grow-1 flex flex-col gap-6px">
									<div class="flex gap-6px align-center">
										<h2 class="text-primary prose-lg-b">Key metrics</h2>
										<MetricInfo dataRangeText={dataDateRangeText()} />
									</div>
									<span class="text-tertiary prose-sm">
										{dataDateRangeText()}
									</span>
								</div>
							}
							metricSummaries={Object.entries(KeyMetrics).map(([id, info]) => {
								const metric = id as DashboardMetric;
								const enabled = () => selectedMetric() === metric;
								const value = () => ({
									current: (activeMetrics()?.current[metric]?.aggregate.value ??
										null) as number | null,
									previous: (activeMetrics()?.previous[metric]?.aggregate
										.value ?? null) as number | null,
								});

								const diffRatio = () => {
									const v = value();
									if (
										v.current == null ||
										v.previous == null ||
										v.previous === 0
									)
										return null;
									return (
										((v.current - v.previous) / Math.abs(v.previous)) * 100
									);
								};

								return (
									<Suspense fallback={<Skeleton />}>
										<RadioGroup.Item
											value={id}
											class={cn(
												"relative h-full p-10px env-desktop:(p-20px) transition-all rounded-6px",
												enabled() ? "bg-white" : "bg-background-dark",
												isPendingDebounced() && "opacity-50",
											)}
										>
											<Show when={enabled()}>
												<div class="absolute inset-0 rounded-6px border-1 border-primary" />
											</Show>
											<RadioGroup.ItemInput />
											<RadioGroup.ItemControl class="h-full flex flex-col justify-between">
												<h3 class="prose-xs env-desktop:(prose-sm)">
													{environment === "mobile"
														? (info.shortTitle ?? info.title)
														: info.title}
												</h3>
												<div class="flex flex-col gap-1 env-desktop:(flex-row justify-between flex-wrap)">
													<p class="prose-md-b env-desktop:(prose-xl-b)">
														{info.formatValue?.(
															value().current ?? info.defaultValue,
														) ??
															value().current ??
															info.defaultValue}
													</p>
													<div
														class={cn(
															diffRatio() == null && "invisible", // NOTE: This is a workaround to handle a variety of cases instead of using Show
															"prose-2xs env-desktop:(prose-md) flex gap-1 items-center",
															(diffRatio() ?? 0) > 0
																? "text-functional-green-dark"
																: "text-functional-red-dark",
															"gap-1 env-desktop:(gap-1.5)",
														)}
													>
														<i
															class={cn(
																"inline-block",
																(diffRatio() ?? 0) > 0
																	? "i-bp-increase"
																	: "i-bp-decrease",
																"size-8px env-desktop:(size-12px)",
															)}
														/>
														{Math.abs(diffRatio() ?? 0).toFixed(2)}%
													</div>
												</div>
											</RadioGroup.ItemControl>
										</RadioGroup.Item>
									</Suspense>
								);
							})}
							chart={
								<Suspense fallback={<Skeleton height="calc(100% - 30px)" />}>
									<Show when={activeMetrics()}>
										{(metrics) => (
											<MetricChart
												range={{
													from: Temporal.PlainDate.from(metrics().current.from),
													to: Temporal.PlainDate.from(metrics().current.to),
												}}
												data={
													selectedMetric()
														? (metrics().current?.[
																selectedMetric()!
															]?.daily.filter((v) => v != null) ?? [])
														: []
												}
												class={cn(
													"transition-opacity",
													isPendingDebounced() && "opacity-50",
													"env-desktop:(rounded-16px border-1 border-border-light)",
												)}
											/>
										)}
									</Show>
								</Suspense>
							}
							metricSummaryValue={selectedMetric()}
							onMetricSummaryChange={setSelectedMetric}
						/>
					)}
				</Show>
			</Suspense>
		</div>
	);
};

export default DashboardActivity;
