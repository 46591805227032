import {
	CategoryScale,
	Chart,
	LineController,
	LineElement,
	LinearScale,
	type Plugin,
	PointElement,
	Tooltip,
} from "chart.js";

declare module "chart.js" {
	interface PluginOptionsByType<TType> {
		customCanvasBackgroundColor?: {
			color: string;
		};
	}
}

// ref: https://www.chartjs.org/docs/latest/configuration/canvas-background.html
const backgroundColorPlugin: Plugin<"line" | "bar"> = {
	id: "customCanvasBackgroundColor",
	beforeDraw: (chart, args, options) => {
		const { ctx } = chart;
		ctx.save();
		const color = (options as { color: string }).color ?? "transparent";
		ctx.fillStyle = color;
		ctx.fillRect(0, 0, chart.width, chart.height);
		ctx.restore();
	},
};

Chart.register(
	LineController,
	LineElement,
	PointElement,
	CategoryScale,
	LinearScale,
	Tooltip,
	backgroundColorPlugin,
);

export { Chart };
