import { withSentryErrorBoundary } from "@sentry/solidstart";
import type { Component } from "solid-js";
import {
	ErrorBoundary as RawErrorBoundary,
	createEffect,
	mergeProps,
} from "solid-js";
import { Button } from "./ui/button";

type ErrorBoundaryProps = Parameters<typeof RawErrorBoundary>[0];

export const ErrorBoundary = ((props) => {
	const SentryErrorBoundary = withSentryErrorBoundary(RawErrorBoundary);
	const merged = mergeProps<
		[Pick<ErrorBoundaryProps, "fallback">, typeof props]
	>(
		{
			fallback: (error, reset) => (
				<FullScreenErrorFallback error={error} reset={reset} />
			),
		},
		props,
	);
	return <SentryErrorBoundary {...merged} />;
}) as Component<
	Omit<ErrorBoundaryProps, "fallback"> & {
		fallback?: ErrorBoundaryProps["fallback"];
	}
>;

type Props = {
	title?: string;
	message?: string;
	error?: unknown;
	reset: () => void;
};

export const FullScreenErrorFallback = (props: Props) => {
	const merged = mergeProps(
		{
			title: "Oops! Something went wrong",
			message: "Please try again.",
		},
		props,
	);

	createEffect(() => console.error(props.error));

	return (
		<div class="size-full flex flex-col items-center justify-center gap-30px text-center">
			<div class="flex flex-col gap-2">
				<h1 class="prose-md-b text-primary">{merged.title}</h1>
				<p class="prose-sm text-secondary">{merged.message}</p>
			</div>
			<Button variant="highlighted" size="sm" onClick={merged.reset}>
				Try again
			</Button>
		</div>
	);
};
