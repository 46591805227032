import { useChild } from "@contentstech/stackflow-plugin-omniflow";
import { useActivity } from "@contentstech/stackflow-solid";
import {
	type ActivityComponentType,
	useFlow,
} from "@contentstech/stackflow-solid/future";
import { Button as RawButton } from "@kobalte/core/button";
import { createQuery } from "@urql/solid";
import {
	For,
	type JSX,
	type JSXElement,
	Match,
	Show,
	Suspense,
	Switch,
	createEffect,
	createMemo,
	createSignal,
	splitProps,
} from "solid-js";
import { Intl, Temporal } from "temporal-polyfill";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "~/components/ui/dialog";
import {
	Drawer,
	DrawerContent,
	DrawerHeader,
	DrawerTitle,
} from "~/components/ui/drawer";
import { Skeleton } from "~/components/ui/skeleton";
import { WebLink } from "~/components/webLink";
import { environment } from "~/lib/environment";
import { createToeQuery } from "~/lib/gql/createToeQuery";
import { big, graphql, readFragment } from "~/lib/gql/tada";
import { latest } from "~/lib/latest";
import { cn } from "~/lib/utils";
import { useNavContext } from ".";
import { PAYOUT_DETAILS_KEY } from "../settings/payoutDetails";

declare module "@stackflow/config" {
	interface Register {
		PayoutActivity: Record<string, never>;
	}
}

const PAGE_SIZE = 10;

const PayoutHistoryConnectionFragment = graphql(`
	fragment PayoutHistoryConnection on PayoutHistoryConnection {
		nodes {
			id
			type
			amount
			createdAt
			invoice
		}
		pageInfo {
			hasNextPage
			endCursor
		}
	}
`);

const PayoutQuery = graphql.persisted(
	"sha256:3d0833893e36385089461f4b65674b5416750f254f3d4704cd8399a8ac6ee55d",
	graphql(
		`
			query Payout($first: Int!, $typeFilter: PayoutHistoryType) {
				payouts {
					balance
					latest: history (
						first: 1
						filter: { type: ACCRUED }
					) {
						nodes {
							id
							amount
						}
					}
					pending: history (
		  			first: 1
						filter: { type: PENDING }
					) {
						nodes {
							id
							amount
						}
					}
					history(
						first: $first
						filter: { type: $typeFilter }
					) {
						...PayoutHistoryConnection
					}
				}
			}
		`,
		[PayoutHistoryConnectionFragment],
	),
);

const PayoutPaginationQuery = graphql.persisted(
	"sha256:7f775fd7ad541f749e1d3386ebfd420ce181e6ed0dcbedff9a5ea56cbff09951",
	graphql(
		`
			query PayoutPagination($first: Int!, $after: String){
				payouts {
					history(first: $first, after: $after) {
						...PayoutHistoryConnection
					}
				}
			}
		`,
		[PayoutHistoryConnectionFragment],
	),
);

const typeFilters = [
	{
		label: "All",
		value: null,
	},
	{
		label: "Payout Completed",
		value: "COMPLETED",
	},
	{
		label: "Accrued Balance",
		value: "ACCRUED",
	},
] as const satisfies {
	label: string;
	value: ReturnType<typeof graphql.scalar<"PayoutHistoryType">> | null;
}[];

type HistoryTypeFilter = (typeof typeFilters)[number];

const PayoutActivity: ActivityComponentType<"PayoutActivity"> = () => {
	const [filterDrawerOpen, setFilterDrawerOpen] = createSignal(false);
	const nav = useNavContext();
	const actions = useFlow();

	const [typeFilter, setTypeFilter] = createSignal<HistoryTypeFilter>(
		typeFilters[0],
	);
	const [after, setAfter] = createSignal<string | undefined>(undefined);
	const [query] = createToeQuery({
		query: PayoutQuery,
		variables: () => ({ first: PAGE_SIZE, typeFilter: typeFilter().value }),
	});
	createQuery({
		query: PayoutPaginationQuery,
		variables: () => ({ first: PAGE_SIZE, after: after() }),
		pause: () => !after(),
	});

	const dateFormat = Intl.DateTimeFormat("en", {
		year: "numeric",
		month: "short",
		day: "numeric",
	});
	const timeFormat = Intl.DateTimeFormat("en", {
		hour: "2-digit",
		minute: "2-digit",
		hour12: false,
	});
	const nextPayoutDate = createMemo(() =>
		Temporal.Now.plainDateISO().with({ day: Number.MAX_SAFE_INTEGER }),
	);

	const lastMonthComparison = createMemo(() => {
		const latestAmount = latest(
			() => query.data?.payouts.latest.nodes[0]?.amount,
		);
		const pendingAmount = latest(
			() => query.data?.payouts.pending.nodes[0]?.amount,
		);
		if (!latestAmount || !pendingAmount) return;
		return big(pendingAmount).div(big(latestAmount)).mul(100);
	});

	const visibleHistory = createMemo(
		() =>
			readFragment(
				PayoutHistoryConnectionFragment,
				latest(() => query.data?.payouts.history),
			)?.nodes.filter((v) => v.type !== "PENDING") ?? [],
	);

	const [isPayoutDetailsRegistered, setIsPayoutDetailsRegistered] =
		createSignal<boolean>(false);

	const child = useChild();
	const hasChild = createMemo(() => !!child?.());
	const activity = useActivity();
	const latestChild = createMemo<JSXElement>(
		(prev) => child?.()?.render() ?? prev,
	);
	createEffect(() => {
		activity()?.isTop; // trigger the effect on mobile
		hasChild(); // trigger the effect on desktop
		// TODO: Remove after the backend api is implemented
		const data = localStorage.getItem(PAYOUT_DETAILS_KEY);
		setIsPayoutDetailsRegistered(!!data);
	});

	return (
		<div class="flex flex-col h-full">
			<AppBar title="Payouts" />
			<div
				class="relative flex-1 flex flex-col gap-32px env-mobile:(px-16px mb-[var(--safe-area-inset-bottom)]) env-desktop:(px-32px max-w-1080px gap-20px)"
				style={{ "padding-bottom": `${nav.size.height ?? 0}px` }}
			>
				<Show when={!isPayoutDetailsRegistered()}>
					<RawButton
						class="flex flex-row py-12px rounded-8px cursor-pointer mt-20px px-16px gap-12px items-center bg-[linear-gradient(90deg,#DEF54F_0%,#7ED0FF_100%)]"
						style={
							environment === "mobile"
								? {
										position: "absolute",
										right: "16px",
										left: "16px",
										bottom: `calc(var(--safe-area-inset-bottom) + ${
											nav.size.height ?? null
										}px)`,
									}
								: {}
						}
						onClick={() => {
							actions.push("PayoutDetailsActivity", {});
						}}
					>
						<i class="inline-block i-bp-bank" />
						<div class="flex flex-col gap-2px text-left">
							<p class="prose-sm-b">Add your bank</p>
							<p class="prose-xs">
								When your payout is ready, it will be sent to this account
							</p>
						</div>
						<div class="flex-1" />
						<i class="inline-block i-bp-arrow-right" />
					</RawButton>
				</Show>
				<div class="flex flex-col gap-12px my-10px env-desktop:(flex-row my-0px)">
					<Suspense fallback={<Skeleton height={132} />}>
						<Card class="flex-1 env-desktop:(flex-row items-start justify-between)">
							<div class="flex flex-col gap-6px env-desktop:(gap-12px)">
								<p class="prose-xs">Balance</p>
								<p class="prose-xl-b">
									${big(query.data?.payouts.balance ?? 0).toFixed(2)}
								</p>
							</div>
							<Button variant="highlighted" size="sm" disabled>
								Withdraw
							</Button>
						</Card>
					</Suspense>
					<Suspense fallback={<Skeleton height={132} />}>
						<Card class="flex-1">
							<div class="flex flex-col gap-6px env-desktop:(gap-12px)">
								<div class="flex justify-between items-center">
									<p class="prose-xs">Next Payout</p>
									<p class="prose-xs text-secondary">
										Est. on {dateFormat.format(nextPayoutDate())}
									</p>
								</div>
								<p class="prose-xl-b">
									$
									{big(
										query.data?.payouts.pending.nodes[0]?.amount ?? 0,
									).toFixed(2)}
								</p>
							</div>
							<Show
								when={lastMonthComparison()}
								fallback={<p class="prose-xs-b">&nbsp;</p>}
							>
								{(v) => (
									<div
										class={cn(
											"prose-xs-b flex items-center gap-1",
											v().lte(0)
												? "text-functional-green-dark"
												: "text-functional-red-dark",
										)}
									>
										<i
											class={cn(
												"inline-block",
												v().lte(0) ? "i-bp-increase" : "i-bp-decrease",
											)}
										/>
										{v().abs().toFixed(2)} vs last month
									</div>
								)}
							</Show>
						</Card>
					</Suspense>
				</div>
				<div class="flex flex-col gap-20px env-desktop:(mt-23px)">
					<div class="flex items-center justify-between">
						<Suspense fallback={<Skeleton width={100} height={30} />}>
							<Show when={query.data}>
								<h3 class="prose-unbounded-lg env-desktop:(prose-unbounded-xl)">
									History
								</h3>
							</Show>
						</Suspense>

						<Suspense fallback={<Skeleton width={40} height={22} />}>
							<Show when={query.data}>
								<RawButton
									class="flex items-center gap-6px text-secondary"
									onClick={() => setFilterDrawerOpen(true)}
								>
									{typeFilter().label}
									<i class="inline-block i-bp-dropdown" />
								</RawButton>
							</Show>
						</Suspense>

						<Drawer
							open={filterDrawerOpen()}
							onOpenChange={setFilterDrawerOpen}
						>
							<DrawerContent>
								<DrawerHeader>
									<DrawerTitle>Filter by</DrawerTitle>
								</DrawerHeader>
								<ul class="flex flex-col py-10px">
									<For each={typeFilters}>
										{(f) => (
											<li>
												<RawButton
													class="flex w-full items-center justify-between px-20px h-58px"
													onClick={() => {
														setTypeFilter(f);
														setFilterDrawerOpen(false);
													}}
												>
													{f.label}
													<Show when={typeFilter().value === f.value}>
														<i class="inline-block i-bp-check-full text-primary" />
													</Show>
												</RawButton>
											</li>
										)}
									</For>
								</ul>
							</DrawerContent>
						</Drawer>
					</div>
				</div>
				<Suspense
					fallback={
						<div class="flex flex-col gap-32px">
							<Skeleton height="66px" />
							<Skeleton height="66px" />
							<Skeleton height="66px" />
						</div>
					}
				>
					<Show
						when={query.data && visibleHistory().length > 0}
						fallback={
							<>
								<div class="text-tertiary flex flex-col items-center justify-center gap-2 flex-1">
									<i class="block i-bp-invoice" />
									<p class="prose-sm text-center">
										You don’t have any payouts yet
										<br />
										Payouts will appear here
									</p>
								</div>
								{/* TODO: make this bank add button */}
								<div class="my-4" />
							</>
						}
					>
						<ul class="flex flex-col gap-8">
							<For each={visibleHistory()}>
								{(history) => (
									<li class="flex flex-col gap-4">
										<div class="prose-xs pb-3 border-b-0.75 border-border-dark">
											{[dateFormat.format, timeFormat.format]
												.map((f) =>
													f(Temporal.PlainDateTime.from(history.createdAt)),
												)
												.join(" ")}
										</div>
										<div class="flex flex-col gap-1">
											<div class="flex justify-between">
												<p
													class="flex items-center gap-6px prose-sm"
													classList={{
														"text-functional-orange":
															history.type === "ACCRUED",
														"text-functional-green-dark":
															history.type === "IN_PROGRESS" ||
															history.type === "COMPLETED",
													}}
												>
													<Switch>
														<Match when={history.type === "ACCRUED"}>
															<i class="inline-block i-bp-payout-accrued" />
															Accrued Balance
														</Match>
														<Match when={history.type === "IN_PROGRESS"}>
															<i class="inline-block i-bp-payout-progress" />
															In Progress
														</Match>
														<Match when={history.type === "COMPLETED"}>
															<i class="inline-block i-bp-payout-completed" />
															Payout Completed
														</Match>
													</Switch>
												</p>
												<span class="prose-md-b">
													${big(history.amount).toFixed(2)}
												</span>
											</div>
											<Show when={history.invoice}>
												{(invoice) => (
													<WebLink
														href={invoice()}
														class="text-end prose-xs underline text-functional-blue"
													>
														View invoice
													</WebLink>
												)}
											</Show>
										</div>
									</li>
								)}
							</For>
						</ul>
					</Show>
				</Suspense>
			</div>
			<Show when={child}>
				<Dialog open={hasChild()} onOpenChange={actions.pop}>
					<DialogContent class="env-desktop:(max-h-800px overflow-y-auto)">
						<DialogHeader onClose={() => actions.pop()}>
							<DialogTitle class="text-primary">Payout details</DialogTitle>
						</DialogHeader>
						{latestChild()}
					</DialogContent>
				</Dialog>
			</Show>
		</div>
	);
};

type CardProps = JSX.HTMLAttributes<HTMLDivElement>;

const Card = (props: CardProps) => {
	const [locals, others] = splitProps(props, ["class"]);

	return (
		<div
			class={cn(
				"flex flex-col items-stretch gap-12px rounded-8px p-16px bg-background-dark text-primary env-desktop:(bg-white p-24px)",
				locals.class,
			)}
			{...others}
		/>
	);
};

export default PayoutActivity;
