import {
	type Accessor,
	createComputed,
	createSignal,
	startTransition,
	untrack,
} from "solid-js";

export const createDeferredValue = <T>(source: Accessor<T>) => {
	const [value, setValue] = createSignal<T>(untrack(source));

	createComputed(() => {
		startTransition(() => setValue(() => source()));
	});

	return value;
};
