import { mapRange } from "@solid-primitives/range";
import { createElementSize } from "@solid-primitives/resize-observer";
import { debounce, leading, throttle } from "@solid-primitives/scheduled";
import {
	type Accessor,
	createEffect,
	createMemo,
	createSignal,
	onCleanup,
	untrack,
} from "solid-js";

type VirtualizerOptions = {
	itemCount: Accessor<number>;
	skeletonCountBase?: number;
	size: number;
	gap: number;
	overscan?: number;
	hasMoreContents?: Accessor<boolean>;
	onSkeletonReached?: () => void;
};

export const createVirtualizer = (options: VirtualizerOptions) => {
	const [skeletonCount, setSkeletonCount] = createSignal(
		options.skeletonCountBase ?? 0,
	);
	const [scrollContainer, setScrollContainer] = createSignal<HTMLElement>();
	const [header, setHeader] = createSignal<HTMLElement>();
	const [scrollTop, setScrollTop] = createSignal(0);
	const scrollContainerSize = createElementSize(scrollContainer);
	const headerSize = createElementSize(() => header());

	createEffect(() => {
		const container = scrollContainer();
		if (!container) return;
		const update = () => setScrollTop(container.scrollTop);
		update();
		container.addEventListener("scroll", update);
		onCleanup(() => container.removeEventListener("scroll", update));
	});

	const totalCount = createMemo(() => options.itemCount() + skeletonCount());

	const windowSize = createMemo(
		() => (scrollContainerSize?.height ?? 0) - (headerSize.height ?? 0),
	);
	const contentHeight = createMemo(
		() => totalCount() * options.size + (totalCount() - 1) * options.gap,
	);

	const visibleStart = createMemo(() =>
		Math.max(
			Math.floor(scrollTop() / (options.size + options.gap)) -
				(options.overscan ?? 0),
			0,
		),
	);
	const visibleEnd = createMemo(() =>
		Math.max(
			Math.min(
				Math.ceil(
					(scrollTop() + windowSize()) / (options.size + options.gap) + 5,
				),
				totalCount(),
			),
			0,
		),
	);
	const visibleItems = mapRange(
		visibleStart,
		visibleEnd,
		() => 1,
		(index) => ({
			index,
			top: index * (options.size + options.gap),
			isSkeleton: index >= options.itemCount(),
		}),
	);

	const end = createMemo(() =>
		Math.ceil((scrollTop() + windowSize()) / (options.size + options.gap) + 5),
	);
	const skeletonReached = createMemo(() => end() >= options.itemCount());
	const bottomReached = createMemo(() => end() >= totalCount());

	createEffect(() => {
		if (options.hasMoreContents?.() === false) {
			setSkeletonCount(0);
		}
	});

	const resetSkeletonCount = debounce(() => {
		if (untrack(() => options.hasMoreContents?.()) === false) return;
		setSkeletonCount(options.skeletonCountBase ?? 0);
	}, 1000);
	createEffect(() => {
		void options.itemCount();

		if (skeletonReached()) {
			options.onSkeletonReached?.();
			resetSkeletonCount.clear();
		} else {
			resetSkeletonCount();
		}
	});

	const increaseSkeletonCount = leading(
		throttle,
		() => {
			if (untrack(() => options.hasMoreContents?.()) === false) return;
			setSkeletonCount((count) => count + (options.skeletonCountBase ?? 0));
		},
		100,
	);
	createEffect(() => {
		if (bottomReached() && options.hasMoreContents?.()) {
			increaseSkeletonCount();
		}
	});

	return {
		scrollContainerRef: setScrollContainer,
		headerRef: setHeader,
		contentHeight,
		visibleItems,
		skeletonReached,
		bottomReached,
	};
};
