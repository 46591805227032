const colors = {
	primary: "23 22 22",
	"primary-foreground": "255 255 255",
	"primary-disabled": "199 199 199",

	secondary: "89 89 89",
	tertiary: "152 152 152",

	"border-dark": "199 199 199",
	"border-light": "229 229 229",

	"brand-lime": "222 245 79",
	"brand-tangerine": "251 142 41",
	"brand-skyblue": "106 201 255",
	"brand-applegreen": "45 222 105",

	input: "221 220 219",

	"highlighted-start": "222 245 79",
	"highlighted-end": "126 208 255",

	background: "252 250 245",
	"background-off-white": "230 223 219",
	"background-dark": "245 242 234",

	"functional-green-dark": "35 143 92",
	"functional-red-dark": "221 60 59",
	"functional-red": "255 94 94",
	"functional-orange": "249 105 0",
	"functional-blue": "42 112 234",
};

export const getColor = (
	name: keyof typeof colors,
	type: "var" | "raw" = "var",
) => {
	if (type === "var") return `rgb(var(--${name}))`;
	return `rgb(${colors[name]})`;
};

export const getColorPreflights = () => {
	const vars = Object.entries(colors).map(([name, value]) => {
		return `--${name}: ${value};`;
	});
	return `
		:root {
			${vars.join("\n")}
		}
	`;
};

export const getUnocssColorDefinitions = () => {
	const groups = {} as Record<string, Record<string, string>>;

	for (const name of Object.keys(colors)) {
		const [group, ...maybeIdent] = name.split("-");
		if (!group) continue;
		const ident = maybeIdent.some(Boolean)
			? maybeIdent.join("-").replaceAll(/-([a-z])/g, (_, c) => c.toUpperCase())
			: "DEFAULT";

		if (!groups[group]) groups[group] = {};
		groups[group][ident] = `rgb(var(--${name}) / <alpha-value>)`;
	}

	return groups;
};
