import { App } from "@capacitor/app";
import {
	makeTemplate,
	useRoutes,
} from "@contentstech/stackflow-plugin-history-sync/solid";
import {
	type StackflowSolidPlugin,
	useFlow,
} from "@contentstech/stackflow-solid/future";
import { type JSX, createSignal, onCleanup, onMount, untrack } from "solid-js";

export const deepLinkPlugin = (): StackflowSolidPlugin => {
	const [stackSize, setStackSize] = createSignal(1);
	return () => ({
		key: "@backpac/web-ui/deepLinkPlugin",
		wrapStack({ stack }) {
			return (
				<DeepLinkHandler stackSize={stackSize()}>
					{stack.render()}
				</DeepLinkHandler>
			);
		},
		onPushed: () => setStackSize((s) => s + 1),
		onPopped: () => setStackSize((s) => s - 1),
	});
};

type DeepLinkHandlerProps = {
	stackSize: number;
	children: JSX.Element;
};

const DeepLinkHandler = (props: DeepLinkHandlerProps) => {
	const routes = useRoutes();
	const actions = useFlow();

	const handleDeepLink = (rawUrl: string) => {
		untrack(() => {
			try {
				const url = new URL(rawUrl);
				for (const route of routes) {
					const template = makeTemplate(route);
					const parsed = template.parse(url.pathname + url.search);
					if (!parsed) continue;
					actions.pop(props.stackSize - 1, { animate: false });
					type ActivityName = Parameters<(typeof actions)["replace"]>[0];
					actions.replace(route.activityName as ActivityName, parsed);
					return;
				}
				console.warn(`Unhandled deep link "${rawUrl}"`);
			} catch (e) {
				console.error(`Failed to handle deep link "${rawUrl}"`, e);
			}
		});
	};

	onMount(() => {
		const p = App.addListener("appUrlOpen", ({ url }) => handleDeepLink(url));
		onCleanup(() => p.then((subscription) => subscription.remove()));
	});

	return <>{props.children}</>;
};
