import { Capacitor } from "@capacitor/core";
import { useChild } from "@contentstech/stackflow-plugin-omniflow";
import {
	type ActivityComponentType,
	useFlow,
	useStack,
} from "@contentstech/stackflow-solid/future";
import { createForm, getValues, valiForm } from "@modular-forms/solid";
import type { AuthTokenResponse } from "@supabase/supabase-js";
import { type JSXElement, Show, createMemo, createSignal } from "solid-js";
import * as v from "valibot";
import logoDesktop from "~/assets/logo-bg.png";
import { AuthAppScreen } from "~/components/authAppScreen";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "~/components/ui/dialog";
import { Image } from "~/components/ui/image";
import { FormTextField } from "~/components/ui/textField";
import { useTopAlert } from "~/components/ui/topAlert";
import { environment } from "~/lib/environment";
import {
	supabaseAuthThroughApple,
	supabaseAuthThroughGoogle,
} from "~/lib/oauth2";
import { supabase } from "~/lib/supabase";
import { cn } from "~/lib/utils";

declare module "@stackflow/config" {
	interface Register {
		SignInActivity: Record<string, never>;
	}
}

const EmailSignInForm = v.object({
	email: v.pipe(v.string(), v.email("Please enter a valid email address.")),
	password: v.pipe(
		v.string(),
		v.minLength(12, "Your password is at least 12 characters long"),
	),
});

type EmailSignInForm = v.InferOutput<typeof EmailSignInForm>;

export const SignInActivity: ActivityComponentType<"SignInActivity"> = () => {
	const [form, { Form, Field }] = createForm<EmailSignInForm>({
		validate: valiForm(EmailSignInForm),
		validateOn: "blur",
	});
	const [revealPassword, setRevealPassword] = createSignal(false);
	const stack = useStack();
	const actions = useFlow();
	const topAlert = useTopAlert();
	const child = useChild();
	const hasChild = createMemo(() => !!child?.());
	const latestChild = createMemo<JSXElement>(
		(prev) => child?.()?.render() ?? prev,
	);

	const handleOAuthSignIn = async (promise: Promise<AuthTokenResponse>) => {
		const response = await promise;
		if (response.error) {
			topAlert.open({
				variant: "error",
				children: (
					<div>
						<p>Failed to sign in</p>
						<p>Please try again</p>
					</div>
				),
			});
		} else {
			actions.pop(stack().activities.length, { animate: false });
			actions.replace("RootActivity", {});
		}
	};

	return (
		<AuthAppScreen class="env-desktop:(justify-center)">
			<Show when={environment === "mobile"}>
				<AppBar title="Sign in to BackPac" />
			</Show>
			<div class="flex flex-col items-center mt-16px gap-30px env-mobile:(flex-grow-1)">
				<Show when={environment === "desktop"}>
					<div class="flex flex-col items-center gap-20px">
						<Image src={logoDesktop} alt="BackPac Logo" class="size-56px" />
						<h1 class="prose-unbounded-2xl">Sign in to BackPac</h1>
					</div>
				</Show>
				<div class="flex-1 flex flex-col gap-24px px-16px w-100% env-desktop:(max-w-480px)">
					<div class="flex flex-col gap-10px">
						<Button
							variant="login"
							onClick={() => handleOAuthSignIn(supabaseAuthThroughGoogle())}
						>
							<i class="inline-block i-bp-google" />
							Continue with Google
						</Button>
						<Show when={Capacitor.getPlatform() === "ios"}>
							<Button
								variant="login"
								onClick={() => handleOAuthSignIn(supabaseAuthThroughApple())}
							>
								<i class="inline-block i-bp-apple" />
								Continue with Apple
							</Button>
						</Show>
					</div>
					<div class="flex items-center">
						<div class="mx-10px h-1px flex-1 bg-primary" />
						<p class="prose-xs text-primary">
							Sign in with Google
							{Capacitor.getPlatform() === "ios" && ", Apple"} or Email
						</p>
						<div class="mx-10px h-1px flex-1 bg-primary" />
					</div>
					<Form
						onSubmit={async (data) => {
							const response = await supabase.auth.signInWithPassword({
								email: data.email,
								password: data.password,
							});

							if (response.error) {
								topAlert.open({
									variant: "error",
									children:
										response.error.code === "invalid_credentials" ? (
											<p>Password doesn’t match the email. Please try again.</p>
										) : (
											<p>Failed to sign in. Please try again.</p>
										),
								});
							} else {
								actions.pop(stack().activities.length, { animate: false });
								actions.replace("RootActivity", {});
							}
						}}
						class="flex-1 flex flex-col gap-24px env-mobile:(mt-6px mb-40px) text-left env-desktop:(gap-16px)"
					>
						<Field name="email">
							{(field, props) => (
								<FormTextField
									{...props}
									error={field.error}
									label={
										<div class="flex">
											<i class="inline-block align-text-top i-bp-email size-4 mr-6px" />
											Email
										</div>
									}
									type="email"
									placeholder="e.g. hello@backpac.com"
								/>
							)}
						</Field>
						<Field name="password">
							{(field, props) => (
								<FormTextField
									{...props}
									error={field.error}
									label={
										<div class="flex">
											<i class="inline-block align-text-top i-bp-password size-4 mr-6px" />
											Password
										</div>
									}
									type={revealPassword() ? "text" : "password"}
									placeholder="Strong password"
									actions={
										<button
											type="button"
											tabIndex={-1}
											class={cn(
												"p-2",
												revealPassword() ? "i-bp-eye" : "i-bp-eye-closed",
											)}
											onClick={() => setRevealPassword((p) => !p)}
										/>
									}
								/>
							)}
						</Field>
						<div class="flex-1" />
						<Button
							type="submit"
							variant="highlighted"
							disabled={
								!v.safeParse(EmailSignInForm, getValues(form)).success ||
								form.submitting
							}
						>
							Sign in
						</Button>
						<div class="env-desktop:(mt-6px) flex flex-col gap-2px">
							<Button
								variant="text"
								size="sm"
								class="self-center"
								onClick={() =>
									actions.push("RequestPasswordResetActivity", {
										email: getValues(form).email,
									})
								}
							>
								Forgot your password?
							</Button>
							<Show when={environment === "desktop"}>
								<div class="flex items-center justify-center">
									<span class="prose-sm-b">Don't have an account?</span>
									<Button
										variant="text"
										size="sm"
										class="self-center -ml-6px"
										onClick={() => actions.push("SignUpActivity", {})}
									>
										Create account
									</Button>
								</div>
							</Show>
						</div>
					</Form>
				</div>
			</div>
			<Show when={child}>
				<Dialog open={hasChild()} onOpenChange={actions.pop}>
					<DialogContent class="env-desktop:gap-20px pt-32px">
						<DialogHeader onClose={() => actions.pop()}>
							<DialogTitle class="text-primary">
								Forgot your password?
							</DialogTitle>
						</DialogHeader>
						{latestChild()}
					</DialogContent>
				</Dialog>
			</Show>
		</AuthAppScreen>
	);
};
