import {
	type ActivityComponentType,
	useFlow,
	useStack,
} from "@contentstech/stackflow-solid/future";
import { createForm, getValues, valiForm } from "@modular-forms/solid";
import { Show, createSignal } from "solid-js";
import * as v from "valibot";
import logoDesktop from "~/assets/logo-bg.png";
import { AuthAppScreen } from "~/components/authAppScreen";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import { Image } from "~/components/ui/image";
import { FormTextField } from "~/components/ui/textField";
import { useTopAlert } from "~/components/ui/topAlert";
import { environment } from "~/lib/environment";
import { supabase } from "~/lib/supabase";
import { cn } from "~/lib/utils";

declare module "@stackflow/config" {
	interface Register {
		EmailSignUpActivity: Record<string, never>;
	}
}

export const PasswordRules = v.pipe(
	v.string(),
	v.minLength(12, "Password must be at least 12 characters."),
);

const EmailSignUpForm = v.pipe(
	v.object({
		email: v.pipe(v.string(), v.email("Please enter a valid email address.")),
		password: PasswordRules,
		confirmPassword: v.string(),
		name: v.pipe(
			v.string(),
			v.nonEmpty("Name must not be empty."),
			v.maxLength(20, "Please keep it under 20 characters."),
		),
	}),
	v.forward(
		v.partialCheck(
			[["password"], ["confirmPassword"]],
			(input) => input.password === input.confirmPassword,
			"Passwords do not match.",
		),
		["confirmPassword"],
	),
);

type EmailSignUpForm = v.InferOutput<typeof EmailSignUpForm>;

export const EmailSignUpActivity: ActivityComponentType<
	"EmailSignUpActivity"
> = () => {
	const [form, { Form, Field }] = createForm<EmailSignUpForm>({
		validate: valiForm(v.config(EmailSignUpForm, { abortPipeEarly: false })),
		validateOn: "blur",
	});
	const [revealPassword, setRevealPassword] = createSignal(false);
	const topAlert = useTopAlert();
	const stack = useStack();
	const actions = useFlow();

	return (
		<AuthAppScreen class="env-desktop:(justify-center)">
			<Show when={environment === "mobile"}>
				<AppBar title="Sign up with email" />
			</Show>
			<div class="flex flex-col items-center env-mobile:(flex-grow-1)">
				<Show when={environment === "desktop"}>
					<div class="flex flex-col items-center gap-20px">
						<Image src={logoDesktop} alt="BackPac Logo" class="size-56px" />
						<h1 class="prose-unbounded-2xl">Sign up with email</h1>
					</div>
				</Show>
				<Form
					class="flex flex-col gap-24px flex-grow-1 px-16px mt-16px pb-10px env-mobile:(mb-[var(--safe-area-inset-bottom)]) text-left w-100% env-desktop:(gap-16px mt-30px max-w-480px)"
					onSubmit={async (data) => {
						const response = await supabase.auth.signUp({
							email: data.email,
							password: data.password,
							options: {
								data: { name: data.name },
							},
						});
						if (response.error) {
							topAlert.open({
								variant: "error",
								children: (
									<div>
										<p>Failed to sign up</p>
										<p>Please try again</p>
									</div>
								),
							});
						} else if (response.data.user?.confirmed_at) {
							// already signed up
							const response = await supabase.auth.signInWithPassword({
								email: data.email,
								password: data.password,
							});
							if (response.error) {
								topAlert.open({
									variant: "error",
									children: (
										<div>
											<p>We found an account with the same email,</p>
											<p>But failed to sign in with the password.</p>
											<p>Please check the password and try again.</p>
										</div>
									),
								});
							} else {
								actions.pop(stack().activities.length - 1, { animate: false });
								actions.replace("RootActivity", {});
							}
						} else {
							actions.push("EmailSignUpVerifyActivity", { email: data.email });
						}
					}}
				>
					<Field name="email">
						{(field, props) => (
							<FormTextField
								{...props}
								error={field.error}
								label={
									<>
										<i class="inline-block align-text-top i-bp-email size-4 mr-6px" />
										Email
									</>
								}
								type="email"
								placeholder="e.g. hello@backpac.com"
							/>
						)}
					</Field>
					<div class="flex flex-col gap-10px">
						<Field name="password">
							{(field, props) => (
								<FormTextField
									{...props}
									error={field.error}
									label={
										<>
											<i class="inline-block align-text-top i-bp-password size-4 mr-6px" />
											Password
										</>
									}
									type={revealPassword() ? "text" : "password"}
									placeholder="Strong password"
									actions={
										<button
											type="button"
											tabIndex={-1}
											class={cn(
												"p-2",
												revealPassword() ? "i-bp-eye" : "i-bp-eye-closed",
											)}
											onClick={() => setRevealPassword((p) => !p)}
										/>
									}
								/>
							)}
						</Field>
						<Field name="confirmPassword">
							{(field, props) => (
								<FormTextField
									{...props}
									error={field.error}
									type={revealPassword() ? "text" : "password"}
									placeholder="Confirm password"
									actions={
										<button
											type="button"
											tabIndex={-1}
											class={cn(
												"p-2",
												revealPassword() ? "i-bp-eye" : "i-bp-eye-closed",
											)}
											onClick={() => setRevealPassword((p) => !p)}
										/>
									}
								/>
							)}
						</Field>
					</div>
					<Field name="name" validateOn="change">
						{(field, props) => (
							<FormTextField
								{...props}
								error={field.error}
								label={
									<>
										<i class="inline-block align-text-top i-bp-user size-4 mr-6px" />
										Name
									</>
								}
								type="text"
								placeholder="Your name"
								description="Enter up to 20 characters"
								bottomSuffix={<span>{field.value?.length ?? 0}/20</span>}
							/>
						)}
					</Field>
					<div class="flex-1" />
					<Button
						type="submit"
						variant="highlighted"
						size="sm"
						class="env-desktop:(mt-2px) py-15px"
						disabled={
							!v.safeParse(EmailSignUpForm, getValues(form)).success ||
							form.submitting
						}
					>
						Continue
					</Button>
					<Button
						variant="text"
						size="sm"
						class="self-center env-desktop:(mt-24px)"
						onClick={() => actions.push("SignInActivity", {})}
					>
						Have an account? Sign in
					</Button>
				</Form>
			</div>
		</AuthAppScreen>
	);
};
