import {
	type ActivityDefinition,
	type RegisteredActivityName,
	defineConfig,
} from "@stackflow/config";
import { environment } from "~/lib/environment";
import { wrapConfigWithOmniPath } from "./omniPath";

export const config = wrapConfigWithOmniPath(
	environment,
	defineConfig<
		RegisteredActivityName,
		ActivityDefinition<RegisteredActivityName>
	>({
		transitionDuration: 350,
		activities: [
			{
				name: "RootActivity",
				path: "/*",
				routePriority: 1,
				omniflow: {
					mobile: {
						subview: {
							initialActivity: {
								name: "DashboardActivity",
								params: {},
							},
							children: [
								"DashboardActivity",
								"MusicActivity",
								"PayoutActivity",
								"ProfileActivity",
							],
						},
					},
					desktop: {
						subview: {
							initialActivity: {
								name: "DashboardActivity",
								params: {},
							},
							children: [
								"DashboardActivity",
								"MusicActivity",
								"PayoutActivity",
								"ChannelsSettingsActivity",
								"SettingsActivity",
							],
						},
					},
				},
			},
			{
				name: "DashboardActivity",
				path: "/__",
				omniflowPath: {
					segment: "dashboard",
				},
			},
			{
				name: "MusicActivity",
				path: "/__",
				omniflowPath: {
					segment: "music",
				},
			},
			{
				name: "PayoutActivity",
				path: "/__",
				omniflow: {
					desktop: {
						subview: {
							children: ["PayoutDetailsActivity"],
						},
					},
				},
				omniflowPath: {
					segment: "payout",
					childRequired: false,
				},
			},
			{
				name: "ProfileActivity",
				path: "/__",
				omniflowPath: {
					segment: "profile",
				},
			},
			{
				name: "FavoritesActivity",
				path: "/musics/favorites",
			},
			{
				name: "SettingsActivity",
				path: "/settings",
				omniflow: {
					desktop: {
						subview: {
							initialActivity: {
								name: "AccountSettingsActivity",
								params: {},
							},
							children: ["AccountSettingsActivity", "PayoutSettingsActivity"],
						},
					},
				},
				omniflowPath: {
					segment: "settings",
					childRequired: false,
				},
			},
			{
				name: "AccountSettingsActivity",
				path: "/settings/account",
				omniflowPath: {
					segment: "account",
				},
			},
			{
				name: "ChannelsSettingsActivity",
				path: "/settings/channels",
				omniflowPath: {
					segment: "channels",
				},
			},
			{
				name: "PayoutSettingsActivity",
				path: "/settings/payout",
				omniflow: {
					desktop: {
						subview: {
							children: ["PayoutDetailsActivity"],
						},
					},
				},
				omniflowPath: {
					segment: "payout",
					childRequired: false,
				},
			},
			{
				name: "PayoutDetailsActivity",
				path: "/settings/payout/details",
				omniflowPath: {
					segment: "details",
				},
			},
			{
				name: "SignUpActivity",
				path: "/auth/sign-up",
			},
			{
				name: "EmailSignUpActivity",
				path: "/auth/sign-up/email",
			},
			{
				name: "EmailSignUpVerifyActivity",
				path: "/auth/sign-up/email/verify",
			},
			{
				name: "SignInActivity",
				path: "/auth/sign-in",
				omniflow: {
					desktop: {
						subview: {
							children: ["RequestPasswordResetActivity"],
						},
					},
				},
				omniflowPath: {
					childRequired: false,
					childParamName: "modal",
				},
			},
			{
				name: "RequestPasswordResetActivity",
				path: "/auth/forgot-password",
				omniflowPath: {
					segment: "forgot-password",
				},
			},
			{
				name: "ResetPasswordActivity",
				path: "/auth/reset-password",
			},
		],
	}),
);
