import { Capacitor } from "@capacitor/core";
import {
	type ActivityComponentType,
	useFlow,
} from "@contentstech/stackflow-solid/future";
import type { AuthTokenResponse } from "@supabase/supabase-js";
import { Show } from "solid-js";
import logoDesktop from "~/assets/logo-bg.png";
import logo from "~/assets/logo.png";
import { AuthAppScreen } from "~/components/authAppScreen";
import { Button } from "~/components/ui/button";
import { Image } from "~/components/ui/image";
import { useTopAlert } from "~/components/ui/topAlert";
import { WebLink } from "~/components/webLink";
import { TERMS_OF_SERVICE_URL } from "~/lib/constants/urls";
import { environment } from "~/lib/environment";
import {
	supabaseAuthThroughApple,
	supabaseAuthThroughGoogle,
} from "~/lib/oauth2";

declare module "@stackflow/config" {
	interface Register {
		SignUpActivity: Record<string, never>;
	}
}

export const SignUpActivity: ActivityComponentType<"SignUpActivity"> = () => {
	const topAlert = useTopAlert();
	const actions = useFlow();

	const handleOAuthSignUp = async (promise: Promise<AuthTokenResponse>) => {
		const response = await promise;
		if (response.error) {
			topAlert.open({
				variant: "error",
				children: (
					<div>
						<p>Failed to sign up</p>
						<p>Please try again</p>
					</div>
				),
			});
		} else {
			actions.replace("RootActivity", {});
		}
	};

	return (
		<AuthAppScreen>
			<div class="relative flex flex-col flex-grow overflow-hidden env-desktop:justify-center">
				<div class="env-mobile:(flex-grow-1 max-h-[calc(50dvh-150px)])" />
				<div class="flex flex-col items-center env-mobile:mb-[calc(20px+var(--safe-area-inset-bottom))]">
					<div class="flex flex-col items-center gap-20px">
						<Image
							src={environment === "mobile" ? logo : logoDesktop}
							alt="BackPac Logo"
							class="size-20 rounded-[11.2px] env-desktop:(size-56px)"
						/>
						<h1 class="prose-unbounded-2xl">
							{environment === "mobile" ? (
								<>
									See what’s inside
									<br />
									your BackPac
								</>
							) : (
								<>Sign up to BackPac</>
							)}
						</h1>
					</div>
					<div class="flex flex-col items-stretch gap-10px w-100% max-w-480px mt-30px env-mobile:(px-4)">
						<Button
							variant="login"
							onClick={() => handleOAuthSignUp(supabaseAuthThroughGoogle())}
						>
							<i class="inline-block i-bp-google" />
							Continue with Google
						</Button>
						<Show
							when={
								environment === "mobile"
									? Capacitor.getPlatform() === "ios"
									: true
							}
						>
							<Button
								variant="login"
								onClick={() => handleOAuthSignUp(supabaseAuthThroughApple())}
							>
								<i class="inline-block i-bp-apple" />
								Continue with Apple
							</Button>
						</Show>
						<Button
							variant="login"
							onClick={() => actions.push("EmailSignUpActivity", {})}
						>
							<i class="inline-block i-bp-email" />
							Continue with Email
						</Button>
					</div>
					<div class="flex flex-col items-center gap-30px mt-50px env-desktop:(mt-20px gap-40px flex-col-reverse) w-100% max-w-480px">
						{environment === "mobile" ? (
							<Button
								variant="text"
								size="sm"
								onClick={() => actions.push("SignInActivity", {})}
							>
								Have an account? Sign in
							</Button>
						) : (
							<Button
								variant="outline"
								class="prose-md w-100% max-w-480px py-13px border-2px"
								onClick={() => actions.push("SignInActivity", {})}
							>
								Already have an account? Sign in
							</Button>
						)}

						<p class="text-primary prose-xs leading-[1.4]">
							By continuing, you agree to BackPac’s
							<br />{" "}
							<WebLink
								href={TERMS_OF_SERVICE_URL}
								class="font-semibold underline"
							>
								Terms of Service
							</WebLink>{" "}
							and{" "}
							<WebLink
								href={TERMS_OF_SERVICE_URL}
								class="font-semibold underline"
							>
								Privacy Policy
							</WebLink>
						</p>
					</div>
				</div>
			</div>
		</AuthAppScreen>
	);
};
