import { Capacitor } from "@capacitor/core";
import {
	type ActivityComponentType,
	useFlow,
} from "@contentstech/stackflow-solid/future";
import { createForm, getValues, valiForm } from "@modular-forms/solid";
import { Show, createSignal } from "solid-js";
import * as v from "valibot";
import logoDesktop from "~/assets/logo-bg.png";
import { AuthAppScreen } from "~/components/authAppScreen";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import { Image } from "~/components/ui/image";
import { FormTextField } from "~/components/ui/textField";
import { useTopAlert } from "~/components/ui/topAlert";
import { environment } from "~/lib/environment";
import { useKeyboard } from "~/lib/keyboard";
import { supabase } from "~/lib/supabase";
import { cn } from "~/lib/utils";
import { PasswordRules } from "./emailSignUp";

type Platform = "web" | "app";

declare module "@stackflow/config" {
	interface Register {
		ResetPasswordActivity: {
			from: Platform;
		};
	}
}

const ResetPasswordForm = v.pipe(
	v.object({
		newPassword: PasswordRules,
		confirmPassword: v.string(),
	}),
	v.forward(
		v.partialCheck(
			[["newPassword"], ["confirmPassword"]],
			(input) => input.newPassword === input.confirmPassword,
			"Passwords do not match",
		),
		["confirmPassword"],
	),
);

type ResetPasswordForm = v.InferOutput<typeof ResetPasswordForm>;

export const ResetPasswordActivity: ActivityComponentType<
	"ResetPasswordActivity"
> = (props) => {
	const [form, { Form, Field }] = createForm<ResetPasswordForm>({
		validate: valiForm(ResetPasswordForm),
		validateOn: "blur",
	});
	const [revealPassword, setRevealPassword] = createSignal(false);
	const topAlert = useTopAlert();
	const actions = useFlow();
	const keyboard = useKeyboard();
	const currentPlatform: Platform =
		Capacitor.getPlatform() === "web" ? "web" : "app";

	return (
		<AuthAppScreen class="env-desktop:(justify-center items-center)">
			<Show when={environment === "mobile"}>
				<AppBar title="Reset password" />
			</Show>
			<div class="flex flex-col mt-16px gap-30px env-mobile:(flex-grow-1 mb-[var(--safe-area-inset-bottom)] pb-20px) w-100% env-desktop:(max-w-480px)">
				<Show when={environment === "desktop"}>
					<div class="flex flex-col items-center gap-20px">
						<Image src={logoDesktop} alt="BackPac Logo" class="size-56px" />
						<h1 class="prose-unbounded-2xl">Reset password</h1>
					</div>
				</Show>
				<Form
					onSubmit={async (data) => {
						const response = await supabase.auth.updateUser({
							password: data.newPassword,
						});
						if (response.error) {
							return topAlert.open({
								variant: "error",
								children: (
									<div>
										<p>Failed to reset password</p>
										<p>Please try again</p>
									</div>
								),
							});
						}

						topAlert.open({
							variant: "success",
							children: (
								<div>
									<p>Password reset was successful!</p>
									<p>Please sign in with your new password</p>
								</div>
							),
							duration: currentPlatform === props.params.from ? 2000 : 1000000,
						});
						if (currentPlatform === props.params.from) {
							actions.replace("SignInActivity", {});
						}
					}}
					class="flex flex-col text-left px-16px gap-10px self-stretch flex-grow-1"
				>
					<Field name="newPassword">
						{(field, props) => (
							<FormTextField
								{...props}
								error={field.error}
								label={
									<>
										<i class="inline-block align-text-top i-bp-password size-4 mr-6px" />
										Password
									</>
								}
								type={revealPassword() ? "text" : "password"}
								placeholder="Strong password"
								actions={
									<button
										type="button"
										tabIndex={-1}
										class={cn(
											"p-2",
											revealPassword() ? "i-bp-eye" : "i-bp-eye-closed",
										)}
										onClick={() => setRevealPassword((p) => !p)}
									/>
								}
							/>
						)}
					</Field>
					<Field name="confirmPassword">
						{(field, props) => (
							<FormTextField
								{...props}
								error={field.error}
								type={revealPassword() ? "text" : "password"}
								placeholder="Confirm password"
								description="Must be at least 12 characters"
								actions={
									<button
										type="button"
										tabIndex={-1}
										class={cn(
											"p-2",
											revealPassword() ? "i-bp-eye" : "i-bp-eye-closed",
										)}
										onClick={() => setRevealPassword((p) => !p)}
									/>
								}
							/>
						)}
					</Field>
					<div class="flex-1" />
					<Button
						type="submit"
						variant="highlighted"
						size="default"
						class="self-stretch mt-10px h-48px"
						disabled={
							!v.safeParse(ResetPasswordForm, getValues(form)).success ||
							form.submitting
						}
					>
						Submit
					</Button>
				</Form>
			</div>
		</AuthAppScreen>
	);
};
