import {
	type AnyVariables,
	type CreateQueryArgs,
	type CreateQueryResult,
	createQuery,
} from "@urql/solid";

export const createToeQuery = <
	// biome-ignore lint/suspicious/noExplicitAny: this is fine
	Data = any,
	Variables extends AnyVariables = AnyVariables,
>(
	args: CreateQueryArgs<Data, Variables>,
): CreateQueryResult<Data, Variables> => {
	const [query, execute] = createQuery(args);

	const createDataHandler = (path: ReadonlyArray<string | number>) => ({
		get(target: object, prop: string | number | symbol) {
			if (typeof prop === "symbol") return undefined;

			const newPath = [...path, prop];
			const value = Reflect.get(target, prop);
			if (value == null) {
				const error = query.error?.graphQLErrors.find(
					(e) =>
						(e.path?.length ?? -1) === newPath.length &&
						e.path?.reduce(
							(acc, p, i) => acc && String(p) === newPath[i],
							true,
						),
				);
				if (error) throw error;
				return value;
			}
			if (typeof value !== "object") return value;
			return new Proxy(value, createDataHandler(newPath));
		},
	});

	const proxy = new Proxy(query, {
		get(target, prop) {
			if (prop === "data") {
				const data = Reflect.get(target, prop);
				if (data == null || typeof data !== "object") return data;
				return new Proxy(data, createDataHandler([]));
			}

			return Reflect.get(target, prop);
		},
	});
	return [proxy, execute];
};
