import { Capacitor } from "@capacitor/core";
import { useParent } from "@contentstech/stackflow-plugin-omniflow";
import {
	type ActivityComponentType,
	useFlow,
} from "@contentstech/stackflow-solid/future";
import { Button as RawButton } from "@kobalte/core/button";
import { createForm, getValues, valiForm } from "@modular-forms/solid";
import { Show, createSignal } from "solid-js";
import { Dynamic, Portal } from "solid-js/web";
import * as v from "valibot";
import { AuthAppScreen } from "~/components/authAppScreen";
import { Passthrough } from "~/components/passthrough";
import { AppBar } from "~/components/ui/appbar";
import { Button } from "~/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "~/components/ui/dialog";
import { FormTextField } from "~/components/ui/textField";
import { useTopAlert } from "~/components/ui/topAlert";
import { environment } from "~/lib/environment";
import { useKeyboard } from "~/lib/keyboard";
import { supabase } from "~/lib/supabase";

declare module "@stackflow/config" {
	interface Register {
		RequestPasswordResetActivity: {
			email?: string;
		};
	}
}

const RequestPasswordResetValidator = v.object({
	email: v.pipe(v.string(), v.email("Please enter a valid email address.")),
});

type RequestPasswordResetValidator = v.InferOutput<
	typeof RequestPasswordResetValidator
>;

export const RequestPasswordResetActivity: ActivityComponentType<
	"RequestPasswordResetActivity"
> = (props) => {
	const [form, { Form, Field }] = createForm<RequestPasswordResetValidator>({
		validate: valiForm(RequestPasswordResetValidator),
		validateOn: "blur",
		initialValues: { email: props.params.email },
	});
	const [showCompleteDialog, setShowCompleteDialog] = createSignal(false);
	const keyboard = useKeyboard();
	const topAlert = useTopAlert();
	const parent = useParent();
	const actions = useFlow();

	return (
		<Dynamic
			{...(parent
				? { component: Passthrough }
				: {
						component: AuthAppScreen,
						class: "h-100vh",
					})}
		>
			{environment === "mobile" && <AppBar title="Forgot your password?" />}
			<div class="flex-1 flex flex-col text-left env-mobile:(gap-40px px-16px) env-desktop:gap-21px">
				<p class="env-mobile:(prose-sm text-secondary)">
					Please enter your email, and we’ll send you a link to reset your
					password.
				</p>
				<Form
					onSubmit={async (data) => {
						const { origin, from } = Capacitor.isNativePlatform()
							? { origin: "https://backpac.app", from: "app" }
							: { origin: location.origin, from: "web" };
						const response = await supabase.auth.resetPasswordForEmail(
							data.email,
							{
								redirectTo: `${origin}/auth/reset-password?from=${from}`,
							},
						);
						if (response.error) {
							topAlert.open({
								variant: "error",
								children: (
									<div>
										<p>Failed to send reset password email</p>
										<p>Please try again</p>
									</div>
								),
							});
							return;
						}
						setShowCompleteDialog(true);
					}}
				>
					<Field name="email">
						{(field, props) => (
							<FormTextField
								{...props}
								error={field.error}
								label={
									<>
										<i class="inline-block align-text-top i-bp-email size-4 mr-6px" />
										Email
									</>
								}
								type="email"
								placeholder="e.g. hello@backpac.com"
								value={field.value}
							/>
						)}
					</Field>
					{environment === "mobile" ? (
						<div
							class="absolute top-0 inset-x-16px transition-transform"
							style={{
								transform: `translateY(calc(100vh - 100% - max(${keyboard.height()}px, var(--safe-area-inset-bottom)) - 16px))`,
							}}
						>
							<Button
								type="submit"
								variant="highlighted"
								class="w-full"
								disabled={
									!v.safeParse(RequestPasswordResetValidator, getValues(form))
										.success || form.submitting
								}
							>
								Send
							</Button>
						</div>
					) : (
						<div class="flex flex-row justify-end mt-30px gap-10px">
							<Button
								variant="text"
								class="after:hidden text-tertiary prose-sm-b"
								size="desktop"
								onClick={() => actions.pop()}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								variant="highlighted"
								size="desktop"
								disabled={
									!v.safeParse(RequestPasswordResetValidator, getValues(form))
										.success || form.submitting
								}
							>
								Send reset code
							</Button>
						</div>
					)}
				</Form>
			</div>
			<Portal mount={document.body}>
				<Dialog
					open={showCompleteDialog()}
					onOpenChange={(open) => {
						setShowCompleteDialog(open);
						if (!open) actions.pop();
					}}
				>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>Check your inbox</DialogTitle>
							<Show when={environment === "desktop"}>
								<RawButton
									class="cursor-pointer p-8px h-32px bg-transparent hover:bg-transparent"
									onClick={() => {
										setShowCompleteDialog(false);
										actions.pop();
									}}
								>
									<i class="inline-block i-bp-close text-primary size-16px" />
								</RawButton>
							</Show>
						</DialogHeader>
						<p>
							If the email is linked to an account, you'll get instructions to
							reset your password. Check your spam folder if it doesn't arrive,
							and confirm the correct email was entered.
						</p>
						<DialogFooter>
							<Button
								{...(environment === "mobile"
									? { variant: "default" }
									: {
											variant: "highlighted",
											size: "desktop",
										})}
								onClick={() => {
									setShowCompleteDialog(false);
									actions.pop();
								}}
							>
								Okay
							</Button>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</Portal>
		</Dynamic>
	);
};
