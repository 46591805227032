import type { JSX, ValidComponent } from "solid-js";
import { splitProps } from "solid-js";

import type { PolymorphicProps } from "@kobalte/core";
import * as SwitchPrimitive from "@kobalte/core/switch";

import { cn } from "~/lib/utils";

type SwitchProps = SwitchPrimitive.SwitchRootProps & {
	class?: string | undefined;
	control?: Omit<SwitchControlProps, "children">;
	thumb?: SwitchThumbProps;
};

export const ToggleSwitch = (props: SwitchProps) => {
	const [local, others] = splitProps(props, ["control", "thumb"]);
	const isChecked = () => others.checked;
	return (
		<SwitchPrimitive.Root {...others}>
			<SwitchControl {...local.control}>
				<SwitchThumb {...local.thumb} isChecked={isChecked()} />
			</SwitchControl>
		</SwitchPrimitive.Root>
	);
};

type SwitchControlProps = SwitchPrimitive.SwitchControlProps & {
	class?: string | undefined;
	children?: JSX.Element;
};

const SwitchControl = <T extends ValidComponent = "input">(
	props: PolymorphicProps<T, SwitchControlProps>,
) => {
	const [local, others] = splitProps(props as SwitchControlProps, [
		"class",
		"children",
	]);
	return (
		<>
			<SwitchPrimitive.Input class={local.class} />
			<SwitchPrimitive.Control
				class={cn(
					"inline-flex h-[26px] w-10 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent bg-input transition-[color,background-color,box-shadow] data-[disabled]:cursor-not-allowed data-[checked]:bg-brand-lime data-[disabled]:opacity-50",
					local.class,
				)}
				{...others}
			>
				{local.children}
			</SwitchPrimitive.Control>
		</>
	);
};

type SwitchThumbProps = SwitchPrimitive.SwitchThumbProps & {
	isChecked?: boolean;
	class?: string | undefined;
	renderChild?: (checked: boolean) => JSX.Element;
};

const SwitchThumb = <T extends ValidComponent = "div">(
	props: PolymorphicProps<T, SwitchThumbProps>,
) => {
	const [local, others] = splitProps(props as SwitchThumbProps, [
		"class",
		"renderChild",
		"isChecked",
	]);
	return (
		<SwitchPrimitive.Thumb
			class={cn(
				"flex items-center justify-center pointer-events-none size-[22px] translate-x-0 rounded-full bg-white shadow-lg ring-0 transition-transform data-[checked]:translate-x-[14px]",
				local.class,
			)}
			{...others}
		>
			{typeof local.renderChild === "function"
				? local.renderChild(local.isChecked ?? false)
				: null}
		</SwitchPrimitive.Thumb>
	);
};
