import { createMutation } from "@urql/solid";
import { P, match } from "ts-pattern";
import { useTopAlert } from "~/components/ui/topAlert";
import { graphql } from "~/lib/gql/tada";
import { GoogleOAuth2Scopes, googleOAuth2 } from "~/lib/oauth2";

const FetchYoutubeMutation = graphql.persisted(
	"sha256:a0c33949681f68a293a750eb207bbff1f5d9adef002c4a451a553aa9c89d4c68",
	graphql(`
		mutation FetchYoutube($input: FetchYoutubeInput!) {
			fetchYoutube(input: $input) {
				__typename
				... on FetchYoutubePayload {
					channels {
						__typename
						... on Channel {
							id
							name
							profileImage
						}
					}
				}
			}
		}
	`),
);

export const useAddChannel = () => {
	const [, fetchYoutube] = createMutation(FetchYoutubeMutation);
	const topAlert = useTopAlert();

	return async () => {
		const result = await googleOAuth2(GoogleOAuth2Scopes.YouTube);
		const channels = await fetchYoutube({
			input: { accessToken: result.access_token },
		})
			.then((res) =>
				match(res.data?.fetchYoutube)
					.with(P.nullish, () => undefined)
					.with({ channels: P.nonNullable }, ({ channels }) => channels)
					.otherwise(({ __typename }) => __typename),
			)
			.catch(() => undefined);
		if (typeof channels === "string" || channels === undefined) {
			topAlert.open({
				variant: "error",
				children: (
					<div>
						<p>Failed to fetch YouTube channel</p>
						<p>Please try again</p>
					</div>
				),
			});
		} else if (channels.length === 0) {
			topAlert.open({
				variant: "error",
				children: (
					<div>
						<p>Looks like the Google account you are using</p>
						<p>doesn't have any YouTube channel 🥲</p>
					</div>
				),
			});
		}
	};
};
