import { useParent } from "@contentstech/stackflow-plugin-omniflow";
import { useFlow } from "@contentstech/stackflow-solid/future";
import {
	type JSX,
	Match,
	Show,
	Switch,
	createRenderEffect,
	untrack,
} from "solid-js";
import { session } from "~/lib/supabase";

type Props = {
	type: "require-auth" | "require-no-auth";
	children: JSX.Element;
};

export const AuthGuard = (props: Props) => {
	const parent = useParent();
	const actions = useFlow();

	createRenderEffect(() => {
		if (!parent) return;
		untrack(() => {
			if (!session()) {
				if (props.type === "require-auth") {
					actions.replace("SignUpActivity", {});
				}
			} else if (props.type === "require-no-auth") {
				actions.replace("RootActivity", {});
			}
		});
	});

	return (
		<Show when={parent} fallback={props.children}>
			<Switch>
				<Match when={session() && props.type === "require-auth"}>
					{props.children}
				</Match>
				<Match when={!session() && props.type === "require-no-auth"}>
					{props.children}
				</Match>
			</Switch>
		</Show>
	);
};
