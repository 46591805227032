import { createScheduled, debounce } from "@solid-primitives/scheduled";
import { type Accessor, createMemo } from "solid-js";

export const createDebounced = <T>(source: Accessor<T>, delay: number) => {
	const scheduled = createScheduled((fn) => debounce(fn, delay));
	return createMemo<T | undefined>((prev) => {
		const value = source();
		return scheduled() ? value : prev;
	});
};
