import { useActivity, useFlow } from "@contentstech/stackflow-solid/future";
import { Button } from "@kobalte/core/button";
import { type JSX, Show, splitProps } from "solid-js";
import { cn } from "~/lib/utils";

type BlockProps = {
	children: JSX.Element;
	class?: string;
};

const Block = (props: BlockProps) => {
	return (
		<div
			class={cn(
				"px-16px py-14px flex items-center gap-10px env-desktop:(p-32px pb-0px)",
				props.class,
			)}
		>
			{props.children}
		</div>
	);
};

type AppBarProps = JSX.HTMLAttributes<HTMLDivElement> & {
	title?: string;
	subtitle?: string;
	actions?: JSX.Element[];
	topActions?: JSX.Element[];
};

export const AppBar = (props: AppBarProps) => {
	const [locals, others] = splitProps(props, [
		"class",
		"title",
		"subtitle",
		"actions",
		"topActions",
	]);
	const activity = useActivity();
	const actions = useFlow();

	return (
		<div
			class={cn("mt-[var(--safe-area-inset-top)]", locals.class)}
			{...others}
		>
			<Show when={!activity()?.isRoot}>
				<Block>
					<Button onClick={() => actions.pop()}>
						<i class="inline-block i-bp-back" />
					</Button>
					<Show when={locals.topActions}>
						<div class="flex-1" />
						<div class="flex items-center gap-6">{locals.topActions}</div>
					</Show>
				</Block>
			</Show>
			<Show when={locals.title}>
				<Block>
					<h1 class="text-primary prose-unbounded-xl leading-[1.4] env-desktop:(text-30px leading-[1.3])">
						{locals.title}
					</h1>
					<Show when={locals.subtitle}>
						<h2 class="text-tertiary prose-unbounded-xl leading-[1.4]">
							{locals.subtitle}
						</h2>
					</Show>
					<Show when={locals.actions}>
						<div class="flex-1" />
						<div class="flex items-center gap-6">{locals.actions}</div>
					</Show>
				</Block>
			</Show>
		</div>
	);
};
