import { type VariantProps, cva } from "class-variance-authority";
import { type JSX, splitProps } from "solid-js";
import { cn } from "~/lib/utils";

const dividerVariants = cva("border-none", {
	variants: {
		variant: {
			default: "bg-border-light",
			highlighted: "bg-highlighted",
		},
		size: {
			default: "mx-4",
			full: "mx-0",
		},
		thickness: {
			default: "h-1px",
			light: "h-0.75px",
		},
	},
	defaultVariants: {
		variant: "default",
		size: "default",
		thickness: "default",
	},
});

type DividerProps = VariantProps<typeof dividerVariants> &
	JSX.HTMLAttributes<HTMLHRElement>;

export const Divider = (props: DividerProps) => {
	const [locals, others] = splitProps(props, [
		"class",
		"variant",
		"size",
		"thickness",
	]);
	return (
		<hr
			class={cn(
				dividerVariants({
					variant: locals.variant,
					size: locals.size,
					thickness: locals.thickness,
				}),
				locals.class,
			)}
			{...others}
		/>
	);
};
