import { Capacitor } from "@capacitor/core";
import {
	AndroidAnimation,
	DefaultAndroidSystemBrowserOptions,
	DefaultiOSSystemBrowserOptions,
	InAppBrowser,
} from "@capacitor/inappbrowser";
import type { JSX } from "solid-js";
import { Dynamic } from "solid-js/web";

type Props = JSX.AnchorHTMLAttributes<HTMLAnchorElement>;

export const WebLink = (props: Props) => {
	return (
		<Dynamic
			{...props}
			{...(props.href
				? ({
						component: "a",
						onClick: (e) => {
							if (
								Capacitor.isNativePlatform() &&
								props.href?.startsWith("http")
							) {
								e.preventDefault();
								InAppBrowser.openInSystemBrowser({
									url: props.href,
									options: {
										android: {
											...DefaultAndroidSystemBrowserOptions,
											startAnimation: AndroidAnimation.SLIDE_IN_LEFT,
											exitAnimation: AndroidAnimation.SLIDE_OUT_RIGHT,
										},
										iOS: {
											...DefaultiOSSystemBrowserOptions,
										},
									},
								});
							}
							if (typeof props.onClick === "function") {
								props.onClick(e);
							}
						},
						target: "_blank",
					} satisfies Parameters<typeof Dynamic<"a">>[0])
				: { component: "span" })}
		/>
	);
};
