import type { JSX, ValidComponent } from "solid-js";
import { splitProps } from "solid-js";

import type { PolymorphicProps } from "@kobalte/core/polymorphic";
import * as ToastPrimitive from "@kobalte/core/toast";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { Portal } from "solid-js/web";
import { cn } from "~/lib/utils";

const toastVariants = cva(
	"group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md p-4 px-3 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--kb-toast-swipe-end-x)] data-[swipe=move]:(translate-x-[var(--kb-toast-swipe-move-x)] transition-none) data-[opened]:animate-in data-[closed]:animate-out data-[swipe=end]:animate-out data-[closed]:(fade-out-80 slide-out-to-right-full) data-[opened]:slide-in-from-bottom-full",
	{
		variants: {
			variant: {
				default: "bg-primary text-white",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);
type ToastVariant = NonNullable<VariantProps<typeof toastVariants>["variant"]>;

type ToastListProps<T extends ValidComponent = "ol"> =
	ToastPrimitive.ToastListProps<T> & {
		class?: string | undefined;
	};

const Toaster = <T extends ValidComponent = "ol">(
	props: PolymorphicProps<T, ToastListProps<T>> & {
		regionProps?: ToastPrimitive.ToastRegionProps;
	},
) => {
	const [local, others] = splitProps(props as ToastListProps, ["class"]);

	return (
		<Portal>
			<ToastPrimitive.Region {...props.regionProps}>
				<ToastPrimitive.List
					class={cn(
						"fixed bottom-0 z-10 flex max-h-screen w-full flex-col-reverse gap-2 px-10px py-16px",
						local.class,
					)}
					{...others}
				/>
			</ToastPrimitive.Region>
		</Portal>
	);
};

type ToastRootProps<T extends ValidComponent = "li"> =
	ToastPrimitive.ToastRootProps<T> &
		VariantProps<typeof toastVariants> & { class?: string | undefined };

const Toast = <T extends ValidComponent = "li">(
	props: PolymorphicProps<T, ToastRootProps<T>>,
) => {
	const [local, others] = splitProps(props as ToastRootProps, [
		"class",
		"variant",
	]);
	return (
		<ToastPrimitive.Root
			class={cn(toastVariants({ variant: local.variant }), local.class)}
			{...others}
		/>
	);
};

type ToastTitleProps<T extends ValidComponent = "div"> =
	ToastPrimitive.ToastTitleProps<T> & {
		class?: string | undefined;
	};

const ToastTitle = <T extends ValidComponent = "div">(
	props: PolymorphicProps<T, ToastTitleProps<T>>,
) => {
	const [local, others] = splitProps(props as ToastTitleProps, ["class"]);
	return (
		<ToastPrimitive.Title class={cn("prose-sm", local.class)} {...others} />
	);
};

function showToast(props: {
	class?: string;
	message: JSX.Element;
	variant?: ToastVariant;
	duration?: number;
}) {
	ToastPrimitive.toaster.show((data) => (
		<Toast
			toastId={data.toastId}
			variant={props.variant}
			duration={props.duration ?? 2000}
			class={props.class}
		>
			<div class="grid gap-1">
				{props.message && <ToastTitle>{props.message}</ToastTitle>}
			</div>
		</Toast>
	));
}

export { Toaster, Toast, ToastTitle, showToast };
